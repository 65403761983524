import { Table, Divider, Button, Form, Input, Modal, message, Switch, Pagination } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import { Select } from "antd"
import ButtonGroup from "antd/lib/button/button-group"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { SearchOutlined, SettingTwoTone } from "@ant-design/icons"
import { ViewAccountType } from "../components/ViewAccountType"
import { useSearchParams } from "react-router-dom/dist"
import { ProfileRequestForm } from "../components/ProfileRequestForm"
import { ProfilePromptMessageForm } from "../components/ProfilePromptMessageForm"
// import QueryForm from "./components/QueryForm"

const defaultQuery = { topic: "", accountID: "" }

export function RoomList() {
    const [searchParams] = useSearchParams()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch()
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState(defaultQuery)
    const [form] = Form.useForm()

    dispatch(NavActive("Account"))

    useEffect(() => {
        // 从urlquery中获取到的查询
        const searchQuery = Object.fromEntries([...searchParams])
        const queryWithParam = { ...query, ...searchQuery }
        handlePageTo(queryWithParam, 1, 20)
    }, [user?.token])

    const handlePageTo = (query, page, size, touchLoading = true) => {
        setQuery(query)
        form.setFieldsValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.get("/account/room", { page, size, ...query })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data)
                setLoading(false)
                console.log(data)
            })
            .catch(() => { })
    }

    const handleSearch = (values) => {
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, page, size)
    }

    const handleRequestProfileModel = (accountType, accountID, roomName) => {
        let formInstance = null

        jsonRequest.get("/account/profile/request/setting-map", { accountType, accountID, ownerType: "bot_room", ownerID: roomName })
            .then(response => response.json())
            .then(({ data }) => {
                Modal.confirm({
                    width: 1200,
                    title: `群参数设置 - ${roomName}`,
                    content: <ProfileRequestForm formRef={(instance) => formInstance = instance} initialValues={data} />,
                    onOk: (close) => {
                        formInstance
                            .validateFields()
                            .then((v) => {
                                jsonRequest.post("/account/profile/request/setting-map", { accountType, accountID, ownerType: "bot_room", ownerID: roomName, param: v })
                                    .then(response => response.json())
                                    .then(({ data }) => {
                                        message.success("设置成功")
                                        close()
                                    })
                            })
                            .catch(info => {
                                console.log('Validate Failed:', info)
                            })
                        return false
                    }
                })
            })
    }


    const handleProfilePromptModel = (roomID, roomName) => {
        let formInstance = null

        jsonRequest.get("/account/profile/prompt/setting", { ownerType: "bot_room", ownerID: roomID })
            .then(response => response.json())
            .then(({ data }) => {
                Modal.confirm({
                    width: 1200,
                    title: `系统提示设置 - ${roomName}`,
                    content: <ProfilePromptMessageForm formRef={(instance) => formInstance = instance} initialValues={data} />,
                    onOk: (close) => {
                        formInstance
                            .validateFields()
                            .then((v) => {
                                jsonRequest.post("/account/profile/prompt/setting", { ownerType: "bot_room", ownerID: roomID, ...v })
                                    .then(response => response.json())
                                    .then(({ data }) => {
                                        message.success("设置成功")
                                        close()
                                    })
                            })
                            .catch(info => {
                                console.log('Validate Failed:', info)
                            })
                        return false
                    }
                })
            })
    }


    // 终端设置请求参数
    const handleProfileTerminalSet = (accountType, accountID, roomTopic, field, value) => {
        jsonRequest.post(`/account/room/terminal/${field}`, { accountType, accountID, roomTopic, value })
            .then(response => response.json())
            .then(({ data }) => {
                // handlePageTo(query, page, size)
            })
    }

    return <div className="page user">
        <h1>
            群列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="accountID" label="账户ID（昵称）">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="topic" label="群名">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
            </Form>
        </div >

        <Table dataSource={data.data}
            className="flex-table"
            size="middle"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            {/* <Table.Column align="center" width={100} title="所属账号类型" dataIndex="accountType" key="accountType" render={(field) => <ViewAccountType type={field} />} /> */}
            <Table.Column align="center" width={100} title="所属账号昵称" dataIndex="accountID" key="accountID" render={(text, item) => <Link onClick={() => handleSearch({ accountType: item.accountType, accountID: item.accountID })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={200} title="群名或昵称" dataIndex="topic" key="topic" render={(text, item) => <Link onClick={() => handleSearch({ topic: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={100} title="所属账号昵称" dataIndex="accountID" key="accountID" render={(text, item) => <Link onClick={() => handleSearch({ accountType: item.accountType, accountID: item.accountID })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={100} title="群成员总数" dataIndex="raw" key="raw" render={(field) => field?.memberIdList?.length} />
            <Table.Column align="center" width={150} title="信息更新时间" dataIndex="updatedAt" key="updatedAt" render={(field, item) => dayjs(field || item.insertedAt).format("YYYY-MM-DD HH:mm")} />
            <Table.Column align="center" width={100} title="终端启用" dataIndex={["profile", "terminal", "enable"]} key={["profile", "terminal", "enable"]} render={(field, item) => <Switch checkedChildren="启用" unCheckedChildren="不启用" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.accountType, item.accountID, item.topic, "enable", enable) }} />} />
            <Table.Column align="center" width={100} title="终端设置请求参数" dataIndex={["profile", "terminal", "profile_setting_allow"]} key={["profile", "terminal", "profile_setting_allow"]} render={(field, item) => <Switch checkedChildren="允许" unCheckedChildren="禁止" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.accountType, item.accountID, item.topic, "profile_setting_allow", enable) }} />} />

            <Table.Column align="center" width={400} title="操作" render={(field, item) => <>
                <Button type="link" onClick={() => handleRequestProfileModel(item.accountType, item.accountID, item.topic)}>AI请求参数 <SettingTwoTone /></Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => handleProfilePromptModel(item.id, item.topic)}>提示 <SettingTwoTone /></Button>
                <Divider type="vertical" />
                <Link target="_blank" to={`/account/message?accountType=${item.accountType}&accountID=${item.accountID}&roomName=${item.topic}`}>消息历史 <SearchOutlined /></Link>
            </>} />
        </Table>
        <Pagination style={{ margin: 5 }} total={data.total} current={data.page} pageSize={data.size} showQuickJumper onChange={(page, size) => { handlePageTo(query, page, size) }} />

    </div >
}