import { Divider, Form, Input, InputNumber, Select, Slider, Switch } from "antd"
import { useState } from "react"

export function ProfileRequestForm({ formRef, initialValues, style }) {
    const [xmindAIV2Enable, setXmindAIV2Enable] = useState(initialValues?.xmindai_v2?.enable || false)
    const [humanLikeEnable, setHumanLikeEnable] = useState(initialValues?.human_like_param?.enable || false)
    const [sessionParamEnable, setSessionParamEnable] = useState(initialValues?.session_param?.enable || false)
    const [bingDalle3Enable, setBingDalle3Enable] = useState(initialValues?.bing_dalle_3?.enable || false)

    console.log(initialValues)

    return <Form ref={formRef} size="small" autoComplete="off" initialValues={initialValues} style={{ display: "flex", ...style }} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <div style={{ width: "50%" }}>
            <Divider>Xmind AI v2 参数设置</Divider>
            <Form.Item name={["xmindai_v2", "enable"]} label="设置" valuePropName="checked" initialValue={xmindAIV2Enable} required>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" checked={xmindAIV2Enable} onChange={setXmindAIV2Enable} />
            </Form.Item>
            <Form.Item name={["xmindai_v2", "model"]} label="AI模型" initialValue={'4'}>
                <Select disabled={!xmindAIV2Enable}>
                    <Select.Option value={'0'}>GPT35</Select.Option>
                    <Select.Option value={'1'}>GPT4</Select.Option>
                    <Select.Option value={'2'}>GPT_32K</Select.Option>
                    <Select.Option value={'3'}>GPT35_TURBO_16K</Select.Option>
                    <Select.Option value={'4'}>GPT35_TURBO_1106</Select.Option>
                    <Select.Option value={'5'}>GPT4_TURBO</Select.Option>
                    <Select.Option value={'6'}>GPT4_TURBO_VISION</Select.Option>
                    <Select.Option value={'7'}>GPT35_TURBO_0125</Select.Option>
                    <Select.Option value={'8'}>GPT4O</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "temperature"]} label="temperature" initialValue={1}>
                <Slider disabled={!xmindAIV2Enable} max={2} min={0} step={0.1}></Slider>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "top_p"]} label="top_p" initialValue={1}>
                <Slider disabled={!xmindAIV2Enable} max={2} min={0} step={0.1}></Slider>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "presence_penalty"]} label="presence_penalty" initialValue={0}>
                <Slider disabled={!xmindAIV2Enable} max={2} min={-2} step={0.1}></Slider>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "frequency_penalty"]} label="frequency_penalty" initialValue={0} >
                <Slider disabled={!xmindAIV2Enable} max={2} min={-2} step={0.1}></Slider>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "stream"]} label="stream" valuePropName="checked" initialValue={true} required>
                <Switch disabled={!xmindAIV2Enable} checkedChildren="流式响应" unCheckedChildren="块式响应" />
            </Form.Item>
            <Form.Item name={["xmindai_v2", "max_tokens"]} label="max_tokens" initialValue={48} required>
                <InputNumber disabled={!xmindAIV2Enable} min={48} />
            </Form.Item>
        </div>
        <div style={{ width: "50%" }}>
            <Divider>Bing Dalle-3 设置</Divider>
            <Form.Item name={["bing_dalle_3", "enable"]} label="设置" valuePropName="checked" initialValue={bingDalle3Enable} required>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" checked={bingDalle3Enable} onChange={setBingDalle3Enable} />
            </Form.Item>
            <Form.Item name={["bing_dalle_3", "size"]} label="分辨率" initialValue="1024x1024">
                <Select disabled={!bingDalle3Enable}>
                    <Select.Option value="1024x1024">宽1024x高1024</Select.Option>
                    <Select.Option value="1024x1792">宽1024x高1792</Select.Option>
                    <Select.Option value="1792x1024">宽1792x高1024</Select.Option>=
                </Select>
            </Form.Item>
            <Form.Item name={["bing_dalle_3", "style"]} label="风格" initialValue="vivid">
                <Select disabled={!bingDalle3Enable}>
                    <Select.Option value="natural">拟真</Select.Option>
                    <Select.Option value="vivid">漫画</Select.Option>
                </Select>
            </Form.Item>
            <Divider>模拟人输入延迟设置</Divider>
            <Form.Item name={["human_like_param", "enable"]} label="设置" valuePropName="checked" initialValue={humanLikeEnable} required>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" checked={xmindAIV2Enable} onChange={setHumanLikeEnable} />
            </Form.Item>
            <Form.Item name={["human_like_param", "splitChar"]} label="切割字符" help="用于切割GPT文字成为段落，默认：\n\n" initialValue="\n\n">
                <Input disabled={!humanLikeEnable} placeholder="\n\n" />
            </Form.Item>
            <Form.Item name={["human_like_param", "splitPer"]} label="段落数量" help="输出时（如微信）每一条信息包含多少段，默认：1" initialValue={1}>
                <InputNumber disabled={!humanLikeEnable} min={1} addonAfter="段" />
            </Form.Item>
            <Form.Item name={["human_like_param", "wordsPerSeconds"]} label="每段延迟" help="用于控制每段话输出的延迟，默认：10，每秒打10个字。 正常人每秒最多5个字。" initialValue={10}>
                <InputNumber disabled={!humanLikeEnable} min={3} addonAfter="字 / 秒" />
            </Form.Item>
            <Divider>微信历史会话设置</Divider>
            <Form.Item name={["session_param", "enable"]} label="设置" valuePropName="checked" initialValue={sessionParamEnable} required>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" checked={sessionParamEnable} onChange={setSessionParamEnable} />
            </Form.Item>
            <Form.Item name={["session_param", "historyLimit"]} label="历史会话条数" help="0条表示不查找历史会话"  initialValue={30}>
                <InputNumber disabled={!sessionParamEnable} min={0} addonAfter="条" />
            </Form.Item>
            <Form.Item name={["session_param", "latestMinutes"]} label="最近时间" help="往前查多少分钟内的消息"  initialValue={10}>
                <InputNumber disabled={!sessionParamEnable} min={0} addonAfter="分钟" />
            </Form.Item>
        </div>
    </Form>
}