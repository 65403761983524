import { Table, Divider, Button, Form, Input, Modal, message, Switch, Pagination, Select } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import ButtonGroup from "antd/lib/button/button-group"
import dayjs from "dayjs"
import { SearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { ViewGenderType } from "../components/ViewGenderType"
import { useSearchParams } from "react-router-dom/dist"
import { SettingTwoTone } from "@ant-design/icons"
import { ProfileRequestForm } from "../components/ProfileRequestForm"
import { ProfilePromptMessageForm } from "../components/ProfilePromptMessageForm"
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

const defaultQuery = { accountId: "", contactId: "", username: "", id: "" }

export function SubAccountFriendList() {
    const [onAddVisible, setVisit] = useState(false)
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [searchParams] = useSearchParams()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch()
    // 从 URL 查询参数中获取值
    const accountId = searchParams.get("accountId")
    // const username = searchParams.get("username")
    const id = searchParams.get("id")
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState({ accountId: "", contactId: "", username: "" })
    // 创建状态变量存储账号选项和好友选项的数据
    const [accountOptions, setAccountOptions] = useState([])
    const [friendOptions, setFriendOptions] = useState([])
    //主账号下拉框
    const [accountID, setAccountID] = useState([])
    const [selectedAccountID, setSelectedAccountID] = useState([])
    const [selectedTerminalId, setSelectedTerminalId] = useState([])
    const [formErrors, setFormErrors] = useState({});

    //查询功能（路由传参）
    useEffect(() => {
        // 从urlquery中获取到的查询
        const searchQuery = Object.fromEntries([...searchParams])
        const queryWithParam = { ...query, ...searchQuery,id:'' }
        handlePageTo(queryWithParam, 1, 20)
    }, [user?.token])

    useEffect(() => {
        form1.setFieldsValue({
            accountId: selectedAccountID || null,
            terminalId: selectedTerminalId || null,
            contactId: null
        });
    }, [selectedAccountID, selectedTerminalId]);

    // 新增选择弹窗
    const resetForm = () => {
        form1.resetFields();
        form1.setFields([]);
        setFormErrors({});
    };

    const onAddCancel = () => {
        resetForm(); // 重置表单和错误信息
        setVisit(false)
    }

    // 获取 URL 参数
    useEffect(() => {
        if (accountId && id) {
            form1.setFieldsValue({
                accountId: accountId,
                terminalId: id,
                contactId: null
            });
        }
    }, [onAddVisible]);

    const onMaterialSelect = () => {
        if (accountId == null && id == null) {
            resetForm();
        }
        setVisit(true)
    }

    //dispatch 函数用来触发一个 action
    dispatch(NavActive("SubAccount"))

    //获取基本页面
    const handlePageTo = (query, page, size, touchLoading = true) => {
        setData("")//清理缓存
        setQuery(query)
        form.setFieldsValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.get("/account/contact/terminal-list", { page, size, ...query })
            .then(response => response.json())
            .then(({ data }) => {
                if (data === undefined) {
                    setData({ data: [], total: 0, page: 1, size: size });
                } else {
                    setData(data);
                }
                setLoading(false);
            })
            .catch(() => { });
    }

    //搜索功能
    const handleSearch = (values) => {
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, page, size)
    }

    //处理新增子好友
    const onAddFinish = () => {
        form1.validateFields().then((values) => {
            // 手动验证字段
            const errors = {};
            if (!values.accountId) {
                errors.accountId = "请选择主账号";
            }
            if (!values.terminalId) {
                errors.terminalId = "请选择子账号";
            }
            if (!values.contactId) {
                errors.contactId = "请选择好友";
            }
            // 如果有错误，显示错误消息并中止提交
            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                return;
            }
            jsonRequest.post("/account/contact/terminal-add", { ...values })
                .then(response => response.json())
                .then(() => {
                    handlePageTo(query, page, size);
                    message.success('添加成功');
                })
            form1.resetFields();
            onAddCancel();
        });
    };

    // 删除功能
    const handleDelete = (id) => {
        Modal.confirm({
            centered: true,
            width: 400,
            title: '删除',
            content: '确认删除该子账号好友吗？',
            onOk: async (close) => {
                try {
                    const intId = parseInt(id, 10)
                    await jsonRequest.delete(`/account/contact/terminal-delete/${intId}`)
                    handlePageTo(query, page, size)
                    close()
                    message.success('删除成功')
                } catch (error) {
                    message.error('删除失败')
                }
            },
        })
    }

    //AI请求参数
    const handleRequestProfileModel = (accountType, accountID, contactName) => {
        let formInstance = null
        jsonRequest.get("/account/profile/request/setting-map", { accountType, accountID, ownerType: "bot_contact", ownerID: contactName })
            .then(response => response.json())
            .then(({ data }) => {
                Modal.confirm({
                    width: 1200,
                    title: `好友参数设置 - ${contactName}`,
                    content: <ProfileRequestForm formRef={(instance) => formInstance = instance} initialValues={data} />,
                    onOk: (close) => {
                        formInstance
                            .validateFields()
                            .then((v) => {
                                jsonRequest.post("/account/profile/request/setting-map", { accountType, accountID, ownerType: "bot_contact", ownerID: contactName, param: v })
                                    .then(response => response.json())
                                    .then(({ data }) => {
                                        message.success("设置成功")
                                        close()
                                    })
                            })
                            .catch(info => {
                                console.log('Validate Failed:', info)
                            })
                        return false
                    }
                })
            })
    }

    //提示
    const handleProfilePromptModel = (contactID, contactName) => {
        let formInstance = null
        jsonRequest.get("/account/profile/prompt/setting", { ownerType: "bot_contact", ownerID: contactID })
            .then(response => response.json())
            .then(({ data }) => {
                Modal.confirm({
                    width: 1200,
                    title: `系统提示设置 - ${contactName}`,
                    content: <ProfilePromptMessageForm formRef={(instance) => formInstance = instance} initialValues={data} />,
                    onOk: (close) => {
                        formInstance
                            .validateFields()
                            .then((v) => {
                                jsonRequest.post("/account/profile/prompt/setting", { ownerType: "bot_contact", ownerID: contactID, ...v })
                                    .then(response => response.json())
                                    .then(({ data }) => {
                                        message.success("设置成功")
                                        close()
                                    })
                            })
                            .catch(info => {
                                console.log('Validate Failed:', info)
                            })
                        return false
                    }
                })
            })
    }

    // 终端设置请求参数
    const handleProfileTerminalSet = (id, field, value) => {
        jsonRequest.post(`/account/contact/terminal-sub/${field}`, { id, value })
            .then(response => response.json())
            .then(({ data }) => {
                // handlePageTo(query, page, size)
            })
    }

    //好友类型
    const viewType = (type) => {
        switch (type) {
            case '0':
                return "未知"
            case '1':
                return "个人"
            case '2':
                return "公众号"
            case '3':
                return "企业"

            default:
                break
        }
    }

    //获得主账号（主账号下拉框）
    const handleAccount = () => {
        jsonRequest.get("/account/list")
            .then(response => response.json())
            .then(({ data }) => {
                const accountIds = data.map(account => account.id)
                setAccountID(accountIds)
            })
            .catch(() => { })
    }

    //获得子账号(由主账号决定)
    const handleAccountList = (selectedAccountID) => {
        const accountID = selectedAccountID
        jsonRequest.get(`/sub-account/list`, { accountID })
            .then(response => response.json())
            .then(({ data }) => {
                const options = data.map(account => ({ username: account.username, terminalId: account.id }))
                setAccountOptions(options)
            })
            .catch(() => { })
    }

    //获得好友列表
    const handleContactList = async (selectedAccountID, terminalID) => {
        const accountID = selectedAccountID
        jsonRequest.get(`/account/contact/list`, { accountID, terminalID })
            .then(response => response.json())
            .then(({ data }) => {
                const friend = data.map(account => ({ id: account.id, contactId: account.contactID }))
                setFriendOptions(friend)
            })
            .catch(() => { })
    }

    // 主账号下拉框的onChange事件处理
    const handleAccountChange = (selectedValue) => {
        setSelectedAccountID(selectedValue);
        // 清空子账号选择框的值
        setSelectedTerminalId([]); // 清空子账号选择框的值
        setFriendOptions([]); // 清空好友列表
        form1.setFieldsValue({
            accountId: selectedValue,
            terminalId: null,
            contactId: null
        });
        // 根据选中的主账号获取对应的账号列表
        handleAccountList(selectedValue);
    }

    // 子账号下拉框的onChange事件处理
    const handleTerminalChange = (selectedValue) => {
        setSelectedTerminalId(selectedValue);
        setFriendOptions([]); // 清空好友列表
        // 根据选中的主账号和子账号获取对应的好友列表
        handleContactList(selectedAccountID, selectedValue);
        // 更新子账号选择框的值
        form1.setFieldsValue({
            accountId: selectedAccountID,
            terminalId: selectedTerminalId,
            contactId: null
        });
    }

    return <div className="page user">
        <h1>
            好友列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="accountId" label="主账号" >
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="username" label="子账号昵称" >
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="contactId" label="好友昵称" >
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
                <div style={{ marginLeft: 'auto' }}>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => {
                        onMaterialSelect()
                        handleAccount()
                        handleAccountList(accountId)
                        handleContactList(accountId, id)
                    }} >添加</Button>
                </div>
            </Form>
        </div>

        <Table dataSource={data.data}
            size="middle"
            className="flex-table"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            <Table.Column align="center" width={100} title="主账号" dataIndex="accountId" key="accountId" render={(text, item) =>
                <Link onClick={() => handleSearch({ accountId: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={100} title="子账号" dataIndex="username" key="username" render={(text, item) =>
                <Link onClick={() => handleSearch({ username: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={120} title="昵称" dataIndex="contactId" key="contactId" render={(text, item) =>
                <Link onClick={() => handleSearch({ contactId: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={100} title="备注" dataIndex="alias" key="alias" />
            <Table.Column align="center" width={80} title="类型" dataIndex="type" key="type" render={(field) => viewType(field)} />
            <Table.Column align="center" width={80} title="性别" dataIndex="gender" key="gender" render={field => <ViewGenderType type={field} />} />
            <Table.Column align="center" width={80} title="省市" dataIndex="province" key="province" render={(field, item) =>
                <><span>{field}</span><span>{item.city}</span></>} />
            <Table.Column align="center" width={150} title="信息更新时间" dataIndex="updatedAt" key="updatedAt" render={(field, item) =>
                dayjs(field || item.insertedAt).format("YYYY-MM-DD HH:mm")} />
            <Table.Column align="center" width={100} title="终端启用" dataIndex={["profile", "terminal", "enable"]} key={["profile", "terminal", "enable"]} render={(field, item) =>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.id, "enable", enable) }} />} />
            <Table.Column align="center" width={100} title="终端设置请求参数" dataIndex={["profile", "terminal", "profile_setting_allow"]} key={["profile", "terminal", "profile_setting_allow"]} render={(field, item) =>
                <Switch checkedChildren="允许" unCheckedChildren="禁止" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.id, "profile_setting_allow", enable) }} />} />
            <Table.Column align="center" width={500} title="操作" render={(field, item) => <>
                <Button type="link" onClick={() => handleRequestProfileModel(item.type, item.accountId, item.contactId)}>AI请求参数 <SettingTwoTone /></Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => handleProfilePromptModel(item.id, item.contactId)}>提示 <SettingTwoTone /></Button>
                <Divider type="vertical" />
                <Link target="_blank" to={`/account/message?accountID=${item.accountId}&talkerName=${item.contactId}`}>消息历史 <SearchOutlined /></Link>
                <Divider type="vertical" />
                <Button type="link" onClick={() => handleDelete(item.id)}>删除<MinusCircleOutlined /></Button>
            </>} />
        </Table>
        <Pagination style={{ margin: 5 }} total={data.total} current={data.page} pageSize={data.size} showQuickJumper onChange={(page, size) => { handlePageTo(query, page, size) }} />

        <Modal
            centered
            title="新增好友"
            open={onAddVisible}
            onOk={onAddFinish}
            onCancel={onAddCancel}
            afterClose={() => form1.resetFields()}
            width={500}
        >
            <Form form={form1}>
                <div style={{ maxWidth: '300px', marginLeft: "70px", position: 'relative' }} >
                    <Form.Item label="主账号" name="accountId" id='accountId' initialValue={accountId}
                        validateStatus={formErrors.accountId ? "error" : ""}
                        help={formErrors.accountId}
                    >
                        <Select
                            placeholder="请选择主账号"
                            options={accountID.map(option => ({
                                label: option,
                                value: option,
                                key: option
                            }))}
                            onChange={handleAccountChange}
                        />
                    </Form.Item>
                    <Form.Item label="子账号" name="terminalId" id='terminalId' initialValue={id}
                        validateStatus={formErrors.terminalId ? "error" : ""}
                        help={formErrors.terminalId}
                    >
                        <Select
                            placeholder="请选择子账号"
                            options={accountOptions.map(({ username, terminalId }) => ({
                                label: username,
                                value: terminalId,
                                key: terminalId
                            }))}
                            onChange={handleTerminalChange}
                        />
                    </Form.Item>
                    <Form.Item label="好友" name="contactId" id='contactId'
                        validateStatus={formErrors.contactId ? "error" : ""}
                        help={formErrors.contactId}
                    >
                        <Select placeholder="请选择好友"
                            options={friendOptions.map(({ id, contactId }) =>
                            ({
                                label: contactId,
                                value: id,
                                key: id
                            }))}
                        />
                    </Form.Item>
                </div>
            </Form>
        </Modal>

    </div>

}