import { Table, Divider, Button, Form, Input, Pagination, Modal, Upload, message, Popconfirm, Select } from "antd"
import { MinusCircleOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import ButtonGroup from "antd/lib/button/button-group"
import { useSearchParams } from "react-router-dom/dist"

const defaultQuery = { accountName: '' }

export function KnowledgeBaseListConfig() {
    const [editVisible, setEditVisible] = useState(false)
    const [searchParams] = useSearchParams()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch()
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState(defaultQuery)
    // 所有知识库数据
    const [knowledgeBaseData, setKnowledgeBaseData] = useState([])

    const [form] = Form.useForm()
    const [editForm] = Form.useForm()
    const [editFormErrors, setEditFormErrors] = useState({})

    dispatch(NavActive("KnowledgeBase"))

    const onEditCancel = () => {
        setEditFormErrors({})
        setEditVisible(false)
    }

    /** 编辑完成 */
    const editFinish = () => {
        editForm.validateFields().then((values) => {
            // 手动验证字段
            const errors = {};
            if (values.knowledgeBases.length === 0) {
                errors.knowledgeBases = "请选择知识库"
            }
            // 如果有错误，显示错误消息并中止提交
            if (Object.keys(errors).length > 0) {
                setEditFormErrors(errors)
                return
            }
            // 不更改下拉选项时其value是一个对象(做一个类型判断)
            console.log(values.knowledgeBases)
            const baseIDs = values.knowledgeBases.map((item) => typeof item === 'object' ? item.value : item)
            jsonRequest.post("/baseMessage/updateByAccountID", { accountID: values.accountID, baseIDs: baseIDs })
                .then(response => response.json())
                .then(() => {
                    handlePageTo(query, page, size)
                    message.success('编辑成功')
                })
                .catch(() => {
                    message.error('编辑失败')
                })
            editForm.resetFields()
            onEditCancel()
        })
    }

    const handlePageTo = (query, page, size, touchLoading = true) => {
        setQuery(query)
        form.setFieldValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.post("/baseMessage/getByName", { page, size, ...query })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data)
                setLoading(false)
            })
            .catch(() => { })
    }

    const handleSearch = (values) => {
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, page, size)
    }

    const handleEdit = (record) => {
        const knowledgeBases = record.knowledgeBases.map((item) => ({
            value: item.baseID,
            label: item.name
        }))
        editForm.setFieldsValue({ ...record, knowledgeBases: knowledgeBases })
        setEditVisible(true)
    }

    /** 获取所有的知识库 */
    const getKnowledgeBaseData = () => {
        jsonRequest.get("/knowledgeBase/getAllName")
            .then(response => response.json())
            .then(({ data }) => {
                setKnowledgeBaseData(
                    data.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }))
                )
            })
            .catch(() => { })
    }

    useEffect(() => {
        // 从urlquery中获取到的查询
        const searchQuery = Object.fromEntries([...searchParams])
        const queryWithParam = { ...query, ...searchQuery }
        handlePageTo(queryWithParam, 1, 20)
        getKnowledgeBaseData()
    }, [user?.token])

    const handleDelete = (accountID) => {
        Modal.confirm({
            centered: true,
            width: 400,
            title: '删除',
            content: '确认删除该账号吗？',
            onOk: async (close) => {
                try {
                    await jsonRequest.post(`/baseMessage/deleteByAccountID`, { accountID: accountID })
                    handlePageTo(query, page, size)
                    close()
                    message.success('删除成功')
                } catch (error) {
                    message.error('删除失败')
                }
            },
        })
    }

    return <div className="page user">
        <h1>
            知识库配置
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="accountName" label="主账号">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
            </Form>
        </div >

        <Table dataSource={data.result}
            className="flex-table"
            size="middle"
            loading={loading}
            pagination={false}
            rowKey={d => d.id}>
            <Table.Column align="center"
                width={50}
                title="序号"
                dataIndex="id"
                key="id"
                render={(text, item, index) => (page - 1) * size + (index + 1)}
            />
            <Table.Column align="center"
                width={100}
                title="主账号"
                dataIndex="accountID"
                key="accountID"
            />
            <Table.Column align="center"
                width={100}
                title="知识库名称"
                dataIndex="knowledgeBases"
                key="knowledgeBases"
                render={(text) => (
                    text.map((item, index) =>
                    (<div key={index}>
                        {item.name}
                        <br />
                    </div>))
                )}
            />
            <Table.Column align="center"
                width={150}
                title="操作"
                render={(text, record) => <>
                    <Button type="link" onClick={() => handleEdit(record)}>编辑</Button>
                    <Divider type="vertical" />
                    <Button type="link" onClick={() => handleDelete(record.accountID)}>删除<MinusCircleOutlined />
                    </Button>
                </>}
            />
        </Table>

        <Pagination style={{ margin: 5 }}
            total={data.total}
            current={data.page}
            pageSize={data.size}
            showQuickJumper
            showSizeChanger
            onChange={(page, size) => { handlePageTo(query, page, size) }}
        />

        {/* 配置页面 */}
        <Modal
            centered
            title="配置"
            open={editVisible}
            onOk={editFinish}
            onCancel={onEditCancel}
            afterClose={() => editForm.resetFields()}
            width={500}
        >
            <Form form={editForm} name="edit" autoComplete="off">
                <div style={{ maxWidth: '300px', marginLeft: "70px", position: 'relative' }} >
                    <Form.Item label="主账号" name="accountID" id="accountID" >
                        <Input placeholder="请输入主账号" allowClear readOnly />
                    </Form.Item>
                    <Form.Item label="知识库名称" name="knowledgeBases" id="knowledgeBases"
                        validateStatus={editFormErrors.knowledgeBases ? "error" : ""}
                        help={editFormErrors.knowledgeBases}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="请选择知识库"
                            options={knowledgeBaseData}
                        />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    </div >
}