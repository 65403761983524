
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux"
import { NavActive } from "../features/Global"
import SideMenu from "./components/SideMenu";

export default function AccountModule() {
    const dispatch = useDispatch();

    dispatch(NavActive("Account"));

    return <div className="module page user">
        <div className="side-menu">
            <SideMenu />
        </div>
        <div className="content">
            <Outlet />
        </div>
    </div>
}
