import { Table, Divider, Button, Form, Input, Pagination } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import { Select } from "antd"
import ButtonGroup from "antd/lib/button/button-group"
import { LocalTimeAgo } from "../components/local_time_ago"
import dayjs from "dayjs"
import { SearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { DatePicker } from "antd"
import locale from 'antd/es/date-picker/locale/zh_CN';
import { useSearchParams } from "react-router-dom"
import { ViewAccountType } from "../components/ViewAccountType"
// import QueryForm from "./components/QueryForm"

const defaultQuery = { accountID: "", accountType: "", content: "", messageType: "", receiveAt: [], talkerName: "", roomName: "" }

export function MessageList() {
    const [searchParams] = useSearchParams();

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch();
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState(defaultQuery)
    const [form] = Form.useForm()

    dispatch(NavActive("Account"));

    useEffect(() => {
        // 从urlquery中获取到的查询
        const searchQuery = Object.fromEntries([...searchParams])
        const queryWithParam = { ...query, ...searchQuery }
        setQuery(queryWithParam)
        handlePageTo(queryWithParam, 1, 20)
    }, [user?.token])

    const handlePageTo = (query, page, size, touchLoading = true) => {
        form.setFieldsValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)

        let _query = { ...query }

        if (!!_query.receiveAt) {
            _query.receiveAt = query.receiveAt.map(date => dayjs(date).format("YYYY-MM-DD"))
        }

        jsonRequest.get("/account/messages", { page, size, ..._query })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(() => { })
    }

    const handleSearch = (values) => {
        setPage(1)
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, 1, size)
    }

    const viewTypeText = (type) => {
        switch (type) {
            case 1: return "附件"
            case 2: return "语音"
            case 3: return "联系人"
            case 4: return "聊天记录"
            case 5: return "表情"
            case 6: return "图片"
            case 7: return "文本"
            case 8: return "地图定位"
            case 9: return "小程序"
            case 11: return "传输"
            case 12: return "红包"
            case 13: return "撤回"
            case 14: return "网址分享"
            case 15: return "视频"
            case 16: return "图文"
        }
    }

    const viewContent = (message) => {
        switch (message.type) {
            case 7:
                return message.content
            case 1:
                return "[附件]"
            case 2:
                return "[语音]"
            case 3:
                return "[联系人]"
            case 4:
                return "[聊天历史]"
            case 5:
                return "[表情]"
            case 6:
                return "[图片]"
            case 8:
                return "[地图定位]"
            case 9:
                return "[小程序]"
            case 10:
                return "[群公告]"
            case 11:
                return "[转账]"
            case 12:
                return "[红包]"
            case 13:
                return "[撤回]"
            case 14:
                return "[链接]"
            case 15:
                return "[视频]"

            default:
                return "[未知信息]"
        }
    }

    return <div className="page user">
        <h1>
            消息列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="accountType" label="类型">
                    <Select style={{ minWidth: 120 }}>
                        <Select.Option value="">全部</Select.Option>
                        <Select.Option value="wechat">微信</Select.Option>
                        <Select.Option value="qq">QQ</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="accountID" label="账户ID（昵称）">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="roomName" label="群名">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="talkerName" label="发言人昵称">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="messageType" label="消息类型">
                    <Select style={{ minWidth: 120 }}>
                        <Select.Option value="">全部</Select.Option>
                        <Select.Option value="Text">文本</Select.Option>
                        <Select.Option value="Image">图片</Select.Option>
                        <Select.Option value="Emoticon">表情</Select.Option>
                        <Select.Option value="Audio">语音</Select.Option>
                        <Select.Option value="Attachment">附件</Select.Option>
                        <Select.Option value="Location">地图定位</Select.Option>
                        <Select.Option value="RedEnvelope">红包</Select.Option>
                        <Select.Option value="Url">网址</Select.Option>
                        <Select.Option value="Contact">名片</Select.Option>
                        <Select.Option value="Video">视频</Select.Option>
                        <Select.Option value="Post">图文</Select.Option>
                        <Select.Option value="MiniProgram">小程序</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="content" label="内容">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="receiveAt" label="接收时间">
                    <DatePicker.RangePicker locale={locale} />
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
            </Form>
        </div>

        <Table dataSource={data.data}
            size="middle"
            className="flex-table"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            <Table.Column align="center" width={100} title="账号类型" dataIndex="accountType" key="accountType" render={(field) => <ViewAccountType type={field} />} />
            <Table.Column align="center" width={150} title="账号ID（昵称）" dataIndex="accountID" key="accountID" render={(text, item) => <Link onClick={() => handleSearch({ accountType: item.accountType, accountID: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={200} title="所属群" dataIndex="roomName" key="roomName" render={(text, item) => text ? <Link onClick={() => handleSearch({ roomName: text })}>{text} <SearchOutlined /></Link> : "私聊"} />
            <Table.Column align="center" width={80} title="消息类型" dataIndex="type" key="type" render={(text) => viewTypeText(text)} />
            <Table.Column align="center" width={150} title="发出人" dataIndex="talkerName" key="talkerName" render={(text, item) => <Link onClick={() => handleSearch({ talkerName: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="left" title="内容" dataIndex="content" key="content" render={(text, item) => <div style={{ fontSize: 11, maxHeight: 100, overflowY: "auto" }}><span style={{ color: "grey" }}>{dayjs(item.receiveAt).format("YYYY-MM-DD HH:mm")}</span> &nbsp; {viewContent(item)}</div>} />
            <Table.Column align="center" width={100} title="接收人" dataIndex="receiverName" key="receiverName" />
            <Table.Column align="center" width={100} title="接收时间" dataIndex="receiveAt" key="receiveAt" render={text => <LocalTimeAgo date={text} />} />
        </Table>
        <Pagination style={{ margin: 5 }} total={data.total} current={data.page} pageSize={data.size} showQuickJumper onChange={(page, size) => { handlePageTo(query, page, size) }} />
    </div>
}