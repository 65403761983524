import { Divider, Button, Form, Input, DatePicker, Select } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from "dayjs"
// import QueryForm from "./components/QueryForm"
import { Chart, registerables } from "chart.js"
import { Line } from 'react-chartjs-2';
import { useParams, useSearchParams } from "react-router-dom/dist";

const defaultQuery = { accountType: "", accountID: "", dateRange: [dayjs().subtract(7, 'day'), dayjs()] }

Chart.register(...registerables);

export function RoomMessageStatistic() {
    const [searchParams] = useSearchParams();
    // 从urlquery中获取到的查询
    const searchQuery = Object.fromEntries([...searchParams])
    // 统计类型
    const { statisticType } = useParams()

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch();
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState(defaultQuery)
    const [form] = Form.useForm()

    dispatch(NavActive("Account"));

    useEffect(() => {
        const queryWithParam = { ...query, ...searchQuery }
        handlePageTo(queryWithParam, 1, 20)
    }, [user?.token, statisticType])

    const handlePageTo = (query, page, size, touchLoading = true) => {
        setQuery(query)
        form.setFieldsValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)

        let _query = { ...query }

        if (!!_query.dateRange) {
            _query.dateRange = query.dateRange.map(date => dayjs(date).format("YYYY-MM-DD"))
        }


        jsonRequest.get("/account/room/DAU", { ..._query, statisticField: statisticType })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(() => { })
    }

    const handleSearch = (values) => {
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, page, size)
    }

    return <div className="page user">
        <h1>
            群统计
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="accountType" label="类型">
                    <Select style={{ minWidth: 120 }}>
                        <Select.Option value="">全部</Select.Option>
                        <Select.Option value="wechat">微信</Select.Option>
                        <Select.Option value="qq">QQ</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="accountID" label="账户ID（昵称）">
                    <Input placeholder="模糊查询" ></Input>
                </Form.Item>
                <Form.Item name="dateRange" label="区间">
                    <DatePicker.RangePicker locale={locale} />
                </Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
            </Form>
        </div>

        <Line height={100}
            data={{
                labels: data.labels || [],
                datasets: data.dataSets || []
            }}
            options={{
                responsive: true,
                plugins: {
                    title: { display: true, text: "群统计" }
                }
            }} />

        {/* <Table dataSource={data.data}
                size="middle"
                scroll={{ y: document.documentElement.clientHeight - 370 }}
                loading={loading}
                pagination={{
                    onChange: (page, size) => { handlePageTo(query, page, size) },
                    total: data.total,
                    current: data.page,
                    pageSize: data.size,
                    showQuickJumper: true
                }} rowKey={d => d.id}>
                <Table.Column align="center" width={100} title="账号ID" dataIndex="id" key="id" />
                <Table.Column align="center" width={200} title="群名或昵称" dataIndex="topic" key="topic" />
                <Table.Column align="center" width={200} title="所属账号类型" dataIndex="accountType" key="accountType" render={(field) => <ViewAccountType type={field} />} />
                <Table.Column align="center" width={200} title="所属账号昵称" dataIndex="accountID" key="accountID" />
                <Table.Column align="center" width={200} title="群成员总数" dataIndex="raw" key="raw" render={(field) => field?.memberIdList?.length} />
                <Table.Column align="center" width={200} title="信息更新时间" dataIndex="updatedAt" key="updatedAt" render={(field, item) => dayjs(field || item.insertedAt).format("YYYY-MM-DD HH:mm")} />
                <Table.Column align="center" width={200} title="操作" render={(field, item) => <>
                    <Link target="_blank" to={`/account/message?accountType=${item.accountType}&accountID=${item.accountID}&roomName=${item.topic}`}>消息历史 <SearchOutlined /></Link>
                </>} />
            </Table> */}
    </div>
}