import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import { ConfigProvider } from "antd"
import zhCN from 'antd/lib/locale/zh_CN'
import 'dayjs/locale/zh-cn'

import PageSignIn from "./PageSignIn"
import Layout from "./_Layout"

import Account from "./Account"
import { AccountList } from "./Account/AccountList"
import { MessageList } from "./Account/MessageList"
import { RoomList } from "./Account/RoomList"
import { FriendList } from "./Account/FriendList"
import { RoomMessageStatistic } from "./Account/RoomMessageStatistic"
import { BotModule, MemoryCard } from "./Bot"
import KnowledgeBaseModule from './KnowledgeBase'
import { KnowledgeBaseList } from "./KnowledgeBase/KnowledgeBaseList"
import { KnowledgeBaseListConfig } from "./KnowledgeBase/KnowledgeBaseListConfig"
import { KnowledgeBaseRoomList } from "./KnowledgeBase/KnowledgeBaseRoomList"
import { KnowledgeBaseFriendList } from "./KnowledgeBase/KnowledgeBaseFriendList"
import SubAccountModule from './SubAccount'
import { SubAccountFriendList } from './SubAccount/SubAccountFriendList'
import { SubAccountList } from './SubAccount/SubAccountList'
import { SubAccountRoomList } from './SubAccount/SubAccountRoomList'


function App () {
  return <ConfigProvider locale={zhCN}>
    <HashRouter>
      <Routes>
        <Route path="/sign_in" exact element={<PageSignIn />} ></Route>
        <Route path="/" element={<Layout />}>
          <Route path="/account" element={<Account />}>
            <Route path="/account" element={<AccountList />} ></Route>
            <Route path="/account/message" element={<MessageList />} ></Route>
            {/* <Route path="/account/message/:roomID" element={<MessageList />} ></Route>
            <Route path="/account/message/:accountType/:accountID" element={<MessageList />} ></Route> */}
            <Route path="/account/contact/room" element={<RoomList />} ></Route>
            <Route path="/account/contact/friend" element={<FriendList />} ></Route>
            <Route path="/account/contact/room/:statisticType" element={<RoomMessageStatistic />} ></Route>
          </Route>
          <Route path="/bot" element={<BotModule />}>
            <Route path="/bot/memory-cards" element={<MemoryCard />} ></Route>
          </Route>
          <Route path="/knowledgeBase" element={<KnowledgeBaseModule />}>
            <Route path="/knowledgeBase" element={<KnowledgeBaseList />}></Route>
            <Route path="/knowledgeBase/contact/KnowledgeBaseListConfig" element={<KnowledgeBaseListConfig />}></Route>
            <Route path="/knowledgeBase/contact/room" element={<KnowledgeBaseRoomList />}></Route>
            <Route path="/knowledgeBase/contact/friend" element={<KnowledgeBaseFriendList />}></Route>
          </Route>
          <Route path="/subAccount" element={<SubAccountModule />}>
            <Route path="/subAccount" element={<SubAccountList />}></Route>
            <Route path="/subAccount/contact/room" element={<SubAccountRoomList />}></Route>
            <Route path="/subAccount/contact/friend" element={<SubAccountFriendList />}></Route>
          </Route>
        </Route>
        <Route path="*" element={<PageSignIn />} />
      </Routes>
    </HashRouter>
  </ConfigProvider>
}

export default App
