import { createSlice } from '@reduxjs/toolkit'
import JsonRequest from '../components/json_request'
const user = JSON.parse(localStorage.getItem("sign-in-user") || "null")
const initialState = {
    socket: null,
    // 当前用户
    user: user,
    // 导航栏当前激活的key
    navActiveKey: null,
    // json请求客户端
    jsonRequest: JsonRequest.tokenClient(user?.token),
}

export const counterSlice = createSlice({
    name: 'global',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        socketInit: (state, action) => {
            return { ...state, socket: action.payload }
        },
        navActive: (state, action) => {
            state.navActiveKey = action.payload
        },
        assignJsonClient: (state, action) => {
            return { ...state, jsonRequest: JsonRequest.tokenClient(action.payload) }
        },
        signIn: (state, action) => {
            return { ...state, user: action.payload }
        },
        signOut: (state) => {
            localStorage.setItem("sign-in-user", "null")
            return { ...state, user: null }
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading';
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle';
    //             state.value += action.payload;
    //         });
    // },
})

export const { socketInit, navActive: NavActive, signIn, signOut, assignJsonClient } = counterSlice.actions

export const selectSocket = (state) => state.global.socket
export const selectUser = (state) => state.global.user
export const selectNavActiveKey = (state) => state.global.navActiveKey
export const selectJsonRequest = (state) => state.global.jsonRequest

export default counterSlice.reducer