import { Table, Divider, Button, Form, Input, Modal, message, Switch, Pagination } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import { Select } from "antd"
import ButtonGroup from "antd/lib/button/button-group"
import { ViewAccountType } from "../components/ViewAccountType"
import { ViewGenderType } from "../components/ViewGenderType"
import { Link } from "react-router-dom"
import { LineChartOutlined, SearchOutlined, SettingTwoTone } from "@ant-design/icons"
import { ProfileRequestForm } from "../components/ProfileRequestForm"
// import QueryForm from "./components/QueryForm"

const defaultQuery = { nickname: "", accountType: "", accountID: "", type: "" }

export function AccountList() {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch();
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState(defaultQuery)

    dispatch(NavActive("Account"));

    useEffect(() => {
        handlePageTo(query, 1, 20)
    }, [user?.token])

    const handlePageTo = (query, page, size, touchLoading = true) => {
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.get("/account", { page, size, ...query })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(() => { })
    }

    const handleSearch = (values) => {
        setQuery(values)
        handlePageTo(values, page, size)
    }

    const handleRequestProfileModel = (accountType, accountID) => {
        let formInstance = null

        jsonRequest.get("/account/profile/request/setting-map", { accountType, accountID })
            .then(response => response.json())
            .then(({ data }) => {
                Modal.confirm({
                    width: 1200,
                    title: `账号参数设置 - ${accountID}`,
                    content: <ProfileRequestForm formRef={(instance) => formInstance = instance} initialValues={data} />,
                    onOk: (close) => {
                        formInstance
                            .validateFields()
                            .then((v) => {
                                jsonRequest.post("/account/profile/request/setting-map", { accountType, accountID, param: v })
                                    .then(response => response.json())
                                    .then(({ data }) => {
                                        message.success("设置成功")
                                        close()
                                    })
                            })
                            .catch(info => {
                                console.log('Validate Failed:', info);
                            });
                        return false
                    }
                })
            })
    }


    // 终端设置请求参数
    const handleProfileTerminalSet = (accountType, accountID, roomTopic, field, value) => {
        jsonRequest.post(`/account/terminal/${field}`, { accountType, accountID, roomTopic, value })
            .then(response => response.json())
            .then(({ data }) => {
                // handlePageTo(query, page, size)
            })
    }

    return <div className="page user">
        <h1>
            账号列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="nickname" label="昵称">
                    <Input placeholder="模糊查询"></Input>
                </Form.Item>
                <Form.Item name="accountType" label="类型">
                    <Select style={{ minWidth: 120 }}>
                        <Select.Option value="">全部</Select.Option>
                        <Select.Option value="WECHAT">微信</Select.Option>
                        <Select.Option value="QQ">QQ</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="accountID" label="账户ID（昵称）">
                    <Input placeholder="模糊查询"></Input>
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
            </Form>
        </div>

        <Table dataSource={data.data}
            size="middle"
            className="flex-table"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            <Table.Column align="center" width={100} title="账号ID" dataIndex="id" key="id" />
            <Table.Column align="center" width={100} title="账号类型" dataIndex="type" key="type" render={(field) => <ViewAccountType type={field} />} />
            <Table.Column align="center" width={100} title="昵称" dataIndex="nickname" key="nickname" />
            {/* <Table.Column align="center" width={50} title="电话" dataIndex="phone" key="phone" /> */}
            <Table.Column align="center" width={50} title="性别" dataIndex="gender" key="gender" render={(field) => <ViewGenderType type={field} />} />
            {/* <Table.Column align="center" width={50} title="省份" dataIndex="province" key="province" /> */}
            {/* <Table.Column align="center" width={50} title="城市" dataIndex="city" key="city" /> */}
            <Table.Column align="center" width={100} title="终端启用" dataIndex={["profile", "terminal", "enable"]} key={["profile", "terminal", "enable"]} render={(field, item) => <Switch checkedChildren="启用" unCheckedChildren="不启用" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.type, item.id, item.topic, "enable", enable) }} />} />
            <Table.Column align="center" width={100} title="终端设置请求参数" dataIndex={["profile", "terminal", "profile_setting_allow"]} key={["profile", "terminal", "profile_setting_allow"]} render={(field, item) => <Switch checkedChildren="允许" unCheckedChildren="禁止" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.type, item.id, item.topic, "profile_setting_allow", enable) }} />} />

            <Table.Column align="center" width={500} title="操作" render={(field, item) => <>

                <Button type="link" onClick={() => handleRequestProfileModel(item.type, item.id)}>AI请求参数 <SettingTwoTone /></Button>
                <Divider type="vertical" />
                <Link target="_blank" to={`/account/message?accountType=${item.type}&accountID=${item.id}`}>消息历史 <SearchOutlined /></Link>
                <Divider type="vertical" />
                <Link target="_blank" to={`/subAccount?accountType=${item.type}&accountId=${item.id}`}>子列表 <SearchOutlined /></Link>
                <Divider type="vertical" />
                <Link target="_blank" to={`/account/contact/room?accountType=${item.type}&accountID=${item.id}`}>群列表 <SearchOutlined /></Link>
                <Divider type="vertical" />
                <Link target="_blank" to={`/account/contact/friend?accountType=${item.type}&accountID=${item.id}`}>好友列表 <SearchOutlined /></Link>
                <Divider type="vertical" />
                <Link to={`/account/contact/room/DAU?accountType=${item.type}&accountID=${item.id}`}>群日活 <LineChartOutlined /></Link>
                <Divider type="vertical" />
                <Link to={`/account/contact/room/DAM?accountType=${item.type}&accountID=${item.id}`}>群日消息 <LineChartOutlined /></Link>
            </>} />
        </Table>
        <Pagination style={{ margin: 5 }} total={data.total} current={data.page} pageSize={data.size} showQuickJumper onChange={(page, size) => { handlePageTo(query, page, size) }} />

    </div>
}