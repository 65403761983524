
import defaultAvatar from "./images/avatar.png"
const configFunc = {

    HttpEndpoint: (path) => `${window.SCHEMA}://${window.ENDPOINT}${path}`,
    SocketEndpoint: (path) => `${window.WEBSOCKET_SCHEMA}://${window.ENDPOINT}${path}`,
    AvatarUrl: (avatarUrlOrPath) => {
        let avatar = "";
        if (!!avatarUrlOrPath) {
            avatar = avatarUrlOrPath.match(/^(http|https)/) ? avatarUrlOrPath : configFunc.AttachUrl(avatarUrlOrPath)
        } else {
            avatar = defaultAvatar
        }
        return avatar
    }
}

export default configFunc