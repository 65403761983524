
import { ManOutlined, WomanOutlined, QuestionOutlined} from "@ant-design/icons"

export function ViewGenderType({type}) {
    switch (type) {
        case 0:
            return <QuestionOutlined />
        case 1:
            return <ManOutlined style={{ color: "#0958d9" }} />
        case 2:
            return <WomanOutlined style={{ color: "rgb(235, 47, 150)" }} />

        default:
            break;
    }
}