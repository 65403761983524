import { Form, Input, Button, message, Checkbox } from 'antd'
import useLocalStorageState from 'use-local-storage-state'
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { signIn, selectJsonRequest, assignJsonClient } from './features/Global'
import { useState } from 'react'

export default function SignIn (_props) {
    const jsonRequest = useSelector(selectJsonRequest)
    const [user, setLocalStorageUser] = useLocalStorageState('sign-in-user', null)
    const [currentEvidence, setCurrentEvidence] = useLocalStorageState('current-evidence', null)
    const [submiting, setSubmitting] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const signInAndRedirect = (user, remember) => {
        if (remember) setLocalStorageUser(user)
        dispatch(signIn(user))
        dispatch(assignJsonClient(user.token))
        navigate("/", { replace: true })
    }

    if (!!user) signInAndRedirect(user)

    const onFinish = (values) => {
        setSubmitting(true)

        setCurrentEvidence({ account: values.account, type: "password" })
        jsonRequest.post("/session/by_password", values)
            .then(response => {
                setSubmitting(false)
                return response.json()
            })
            .then(({ code, data: user, message: msg }) => {
                switch (code) {
                    case 200:
                        message.success("登录成功")
                        signInAndRedirect(user, values.remember)
                        break
                    default:
                        message.warning(msg)
                        break
                }
            })
    }

    const onFinishFailed = () => {

    }


    return <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Form
            style={{ width: 300, marginRight: 100 }}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true, account: currentEvidence?.account }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item label="用户名" name="username" rules={[{ required: true, message: '账号不能为空' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="密码" name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                <Checkbox>记住我？</Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" block loading={submiting}>
                    登录
                </Button>
            </Form.Item>
        </Form>
    </div>
}
