import { Table, Divider, Button, Modal, QRCode, Pagination } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import { Switch } from "antd"
import { io } from "socket.io-client"
import configFunc from "../config"
import { Popover } from "antd"
import { message } from "antd"
import { PlusSquareTwoTone } from "@ant-design/icons"
// import QueryForm from "./components/QueryForm"

export default function PageUser({ abc }) {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch();
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(12)
    const [socket, setSocket] = useState(null)
    const [qrcodeModal, setQrcodeModal] = useState({})

    dispatch(NavActive("Bot"));
    useEffect(() => {
        let _socket = io(configFunc.SocketEndpoint(""), { path: `/websocket/bot/socket.io`, transports: ["websocket"], query: { token: user?.token } })

        _socket.on("connect", () => {
            console.log("connected...")
        })

        _socket.on("message-info", ({ message: msg, id }) => {
            message.warning({ content: msg, key: `bot-${id}` })
        })


        _socket.on("qrcode", ({ value, scanStatus, id }) => {
            message.info({ content: "请扫码..", key: `bot-${id}`, duration: 0 })

            let hintText = ""
            let qrcodeColor = "black"
            switch (scanStatus) {
                case 2:
                    hintText = "等待扫码中"
                    qrcodeColor = "black"
                    break;
                case 3:
                    hintText = "已扫码请等待"
                    message.warning({ content: hintText, key: `bot-${id}`, duration: 0 })
                    qrcodeColor = "grey"
                    break;
                case 4:
                    hintText = "已确定，正在登录"
                    qrcodeColor = "green"
                    break;
                case 5:
                    hintText = "已确定，正在登录"
                    qrcodeColor = "read"
                    break;


                default:
                    break;
            }
            setQrcodeModal({
                ...qrcodeModal,
                [id]: Modal.info({
                    title: `扫码登录 - ${hintText}`,
                    width: 500,
                    content: <div>
                        <QRCode value={value} color={qrcodeColor} size={400} />
                    </div>,
                    okText: "取消登录",
                    okButtonProps: { danger: true },
                    onOk: () => {
                        setLoading(false)
                        _socket.emit("stop-bot", { id })
                    },
                    onCancel: () => {
                        setLoading(false)
                        message.destroy(`bot-${id}`)
                    }
                })
            })
        })

        _socket.on("login", ({ id, status }) => {
            let _qrcodeModal = qrcodeModal?.[id]
            console.log(2, _qrcodeModal)
            Modal.destroyAll()
            setLoading(false)

            // 当有bot登录成功，则同步列表
            setTimeout(() => {
                handlePageTo(page, size)
            }, 1000);
            message.destroy()
        })

        _socket.on("logout", ({ id }) => {
            setLoading(false)
            // 当有bot登出，则同步列表,延迟一秒，数据库更新有延迟
            setTimeout(() => {
                handlePageTo(page, size)
            }, 1000);
        })

        setSocket(_socket)

        handlePageTo(1, 20)
        return () => {
            _socket.disconnect()
        }
    }, [user?.token])


    const handleClickNew = () => {
        setLoading(true)
        socket.emit("start-bot", {})
    }

    // 处理点击登录
    const handleClickLogin = (id, title) => {
        setLoading(true)
        message.loading({ content: "请求登录中...", key: `bot-${id}`, duration: 0 })
        socket.emit("start-bot", { id: id })
    }

    // 处理点击下线
    const handleClickLogout = (id) => {
        setLoading(true)
        message.loading({ content: "请求下线中...", key: `bot-${id}`, duration: 0 })
        socket.emit("stop-bot", { id: id })
    }


    const handlePageTo = (page, size, touchLoading = true) => {
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.get("/bot/memory-cards", { page, size })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(() => { })
    }

    const hanldeDelete = (id) => {
        jsonRequest.delete(`/bot/memory-cards/${id}`)
            .then(response => response.json())
            .then(({ success }) => {
                handlePageTo(page, size)
            })
            .catch(() => { })
    }

    return <div className="page user">
        <h1>列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Button type="link" icon={<PlusSquareTwoTone />} loading={loading} onClick={() => handleClickNew()}>新增登录</Button>
            {/* <QueryForm onSearch={handleSearch} values={query}>
                </QueryForm> */}
        </div>

        <Table dataSource={data.data}
            size="middle"
            className="flex-table"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            <Table.Column align="center" title="BOT KEY" dataIndex="id" key="id" />
            <Table.Column align="center" title="昵称" dataIndex="nickname" key="nickname" />
            <Table.Column align="center" title="协议" dataIndex="puppet" key="puppet" />
            <Table.Column align="center" title="状态" dataIndex="status" key="status" render={(value, item) => {
                return <Switch checked={value === 1} unCheckedChildren="离线" checkedChildren="在线" onChange={(checked) => {
                    if (checked)
                        handleClickLogin(item.id, item.nickname)
                    else
                        handleClickLogout(item.id)
                }}></Switch>
            }} />

            <Table.Column render={(_, item) => {
                return <>
                    <Popover content={<Button type="primary" danger onClick={() => hanldeDelete(item.id)}>确定</Button>}>
                        <Button danger type="link" >删除</Button>
                    </Popover >
                </>
            }} />

        </Table>
        <Pagination style={{ margin: 5 }} total={data.total} current={data.page} pageSize={data.size} showQuickJumper onChange={(page, size) => { handlePageTo(page, size) }} />

    </div >
}