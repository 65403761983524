import React from "react"
import { Layout, Menu, Button } from 'antd'
import useLocalStorageState from 'use-local-storage-state'
import { RobotOutlined, UserOutlined, BookOutlined, DeploymentUnitOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import "./Nav.css"
import { selectNavActiveKey, selectUser, signOut } from "../features/Global"
const { Header } = Layout


function Nav (props) {
    const setLocalStorageUser = useLocalStorageState('sign-in-user')[1]

    const activeKey = useSelector(selectNavActiveKey)
    const user = useSelector(selectUser)
    const dispatch = useDispatch()

    const logOut = () => {
        dispatch(signOut())
        // props.logOut()
        setLocalStorageUser(null)
        // history.replace("/")
    }

    return <Layout className="layout" >
        <Header style={{ background: "#fff", padding: 0, display: "flex" }}>
            <Menu theme="light" mode="horizontal" selectedKeys={[activeKey]} style={{ border: "none", flexGrow: 1 }}>
                <Menu.Item key="Bot" icon={<RobotOutlined />}>
                    <Link to="/bot/memory-cards">机器人 Bot</Link>
                </Menu.Item>

                <Menu.Item key="Account" icon={<UserOutlined />}>
                    <Link to="/account">账号 Account</Link>
                </Menu.Item>

                <Menu.Item key="SubAccount" icon={<DeploymentUnitOutlined />}>
                    <Link to="/subAccount">子账号 SubAccount</Link>
                </Menu.Item>
                <Menu.Item key="KnowledgeBase" icon={<BookOutlined />}>
                    <Link to="/knowledgeBase">知识库 Knowledge Base</Link>
                </Menu.Item>
            </Menu>
            <div className="side">
                <span style={{ padding: "4px 15px" }}>{user?.nickname}</span>
                <Button type="link" onClick={() => logOut()}>退出</Button>
            </div>
        </Header>
    </Layout>
}


export default Nav