import { WechatFilled } from "@ant-design/icons";

export function ViewAccountType({type}) {
    switch (type) {
        case "wechat":
            return <WechatFilled style={{ color: "green" }} />

        default:
            return <div>未知</div>;
    }
}