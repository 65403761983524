import React from "react";
import { Menu } from 'antd';
import { useLocation, Link } from "react-router-dom";
import { BarChartOutlined, OrderedListOutlined } from "@ant-design/icons";

export default function SideMenu(props) {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname


    return <Menu style={{ width: 256 }} selectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.ItemGroup key="message" title="账号">
            <Menu.Item key="/account">
                <Link to="/account">
                    <span><OrderedListOutlined /> 列表</span>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key="message" title="消息">
            <Menu.Item key="/account/message">
                <Link to="/account/message">
                    <span><OrderedListOutlined /> 消息列表</span>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key="contact" title="联系人">
            <Menu.Item key="/account/contact/room">
                <Link to="/account/contact/room">
                    <span><OrderedListOutlined /> 群</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/account/contact/friend">
                <Link to="/account/contact/friend">
                    <span><OrderedListOutlined /> 好友</span>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key="statistic" title="统计">
            <Menu.Item key="/account/contact/room/DAU">
                <Link to="/account/contact/room/DAU"  >
                    <span><BarChartOutlined /> 群日活</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/account/contact/room/DAM">
                <Link to="/account/contact/room/DAM" >
                    <span><BarChartOutlined /> 群日消息</span>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}

