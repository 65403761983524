import { Table, Divider, Button, Form, Input, Modal, message, Switch, Pagination } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import ButtonGroup from "antd/lib/button/button-group"
import dayjs from "dayjs"
import { StarFilled, SearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { ViewGenderType } from "../components/ViewGenderType"
import { useSearchParams } from "react-router-dom/dist"
// import QueryForm from "./components/QueryForm"
import { SettingTwoTone } from "@ant-design/icons"
import { ProfileRequestForm } from "../components/ProfileRequestForm"
import { ProfilePromptMessageForm } from "../components/ProfilePromptMessageForm"

const defaultQuery = { nickname: "", accountType: "", accountID: "", type: "" }

export function FriendList() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch();
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState(defaultQuery)

    const [form] = Form.useForm()

    dispatch(NavActive("Account"));

    useEffect(() => {
        // 从urlquery中获取到的查询
        const searchQuery = Object.fromEntries([...searchParams])
        const queryWithParam = { ...query, ...searchQuery }
        handlePageTo(queryWithParam, 1, 20)
    }, [user?.token])

    const handlePageTo = (query, page, size, touchLoading = true) => {
        form.setFieldsValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.get("/account/contact", { page, size, ...query })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(() => { })
    }

    const handleSearch = (values) => {
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, page, size)
    }



    const handleRequestProfileModel = (accountType, accountID, contactName) => {
        let formInstance = null

        jsonRequest.get("/account/profile/request/setting-map", { accountType, accountID, ownerType: "bot_contact", ownerID: contactName })
            .then(response => response.json())
            .then(({ data }) => {
                Modal.confirm({
                    width: 1200,
                    title: `群参数设置 - ${contactName}`,
                    content: <ProfileRequestForm formRef={(instance) => formInstance = instance} initialValues={data} />,
                    onOk: (close) => {
                        formInstance
                            .validateFields()
                            .then((v) => {
                                jsonRequest.post("/account/profile/request/setting-map", { accountType, accountID, ownerType: "bot_contact", ownerID: contactName, param: v })
                                    .then(response => response.json())
                                    .then(({ data }) => {
                                        message.success("设置成功")
                                        close()
                                    })
                            })
                            .catch(info => {
                                console.log('Validate Failed:', info);
                            });
                        return false
                    }
                })
            })
    }


    const handleProfilePromptModel = (contactID, contactName) => {
        let formInstance = null

        jsonRequest.get("/account/profile/prompt/setting", { ownerType: "bot_contact", ownerID: contactID })
            .then(response => response.json())
            .then(({ data }) => {
                Modal.confirm({
                    width: 1200,
                    title: `系统提示设置 - ${contactName}`,
                    content: <ProfilePromptMessageForm formRef={(instance) => formInstance = instance} initialValues={data} />,
                    onOk: (close) => {
                        formInstance
                            .validateFields()
                            .then((v) => {
                                jsonRequest.post("/account/profile/prompt/setting", { ownerType: "bot_contact", ownerID: contactID, ...v })
                                    .then(response => response.json())
                                    .then(({ data }) => {
                                        message.success("设置成功")
                                        close()
                                    })
                            })
                            .catch(info => {
                                console.log('Validate Failed:', info);
                            });
                        return false
                    }
                })
            })
    }


    // 终端设置请求参数
    const handleProfileTerminalSet = (accountType, accountID, contactID, field, value) => {
        jsonRequest.post(`/account/contact/terminal/${field}`, { accountType, accountID, contactID, value })
            .then(response => response.json())
            .then(({ data }) => {
                // handlePageTo(query, page, size)
            })
    }

    const viewType = (type) => {
        switch (type) {
            case 0:
                return "未知"
            case 1:
                return "个人"
            case 2:
                return "公众号"
            case 3:
                return "企业"

            default:
                break;
        }
    }

    return <div className="page user">
        <h1>
            好友列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="accountID" label="账户ID（昵称）">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="contactID" label="好友ID（昵称）">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
            </Form>
        </div>

        <Table dataSource={data.data}
            size="middle"
            className="flex-table"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            <Table.Column align="center" width={150} title="账号ID（昵称）" dataIndex="accountID" key="accountID" render={(text, item) => <Link onClick={() => handleSearch({ accountType: item.accountType, accountID: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={120} title="好友ID(昵称)" dataIndex="contactID" key="contactID" render={(text, item) => <Link onClick={() => handleSearch({ contactID: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={120} title="备注" dataIndex="alias" key="alias" />
            <Table.Column align="center" width={80} title="好友类型" dataIndex="type" key="type" render={(field) => viewType(field)} />
            {/* <Table.Column align="left" title="签名" dataIndex="signature" key="signature" render={(field) => <span style={{ fontSize: 11 }}>{field}</span>} /> */}
            <Table.Column align="center" width={80} title="性别" dataIndex="gender" key="gender" render={field => <ViewGenderType type={field} />} />
            <Table.Column align="center" width={100} title="省市" dataIndex="province" key="province" render={(field, item) => <><span>{field}</span><span>{item.city}</span></>} />
            {/* <Table.Column align="center" width={80} title="星标朋友" dataIndex="hasStar" key="hasStar" render={field => field && <StarFilled style={{ color: "orange" }} />} /> */}
            <Table.Column align="center" width={150} title="信息更新时间" dataIndex="updatedAt" key="updatedAt" render={(field, item) => dayjs(field || item.insertedAt).format("YYYY-MM-DD HH:mm")} />

            <Table.Column align="center" width={100} title="终端启用" dataIndex={["profile", "terminal", "enable"]} key={["profile", "terminal", "enable"]} render={(field, item) => <Switch checkedChildren="启用" unCheckedChildren="不启用" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.accountType, item.accountID, item.contactID, "enable", enable) }} />} />
            <Table.Column align="center" width={100} title="终端设置请求参数" dataIndex={["profile", "terminal", "profile_setting_allow"]} key={["profile", "terminal", "profile_setting_allow"]} render={(field, item) => <Switch checkedChildren="允许" unCheckedChildren="禁止" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.accountType, item.accountID, item.contactID, "profile_setting_allow", enable) }} />} />
            <Table.Column align="center" width={340} title="操作" render={(field, item) => <>

                <Button type="link" onClick={() => handleRequestProfileModel(item.accountType, item.accountID, item.contactID)}>AI请求参数 <SettingTwoTone /></Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => handleProfilePromptModel(item.id, item.contactID)}>提示 <SettingTwoTone /></Button>
                <Divider type="vertical" />
                <Link target="_blank" to={`/account/message?accountType=${item.accountType}&accountID=${item.accountID}&talkerName=${item.contactID}`}>消息历史 <SearchOutlined /></Link>
            </>} />
        </Table>
        <Pagination style={{ margin: 5 }} total={data.total} current={data.page} pageSize={data.size} showQuickJumper onChange={(page, size) => { handlePageTo(query, page, size) }} />

    </div>
}