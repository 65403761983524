import React from "react"
import { Menu } from 'antd'
import { useLocation, Link } from "react-router-dom"
import { OrderedListOutlined } from '@ant-design/icons'

export default function SideMenu (props) {
  const location = useLocation()

  const pathName = location.pathname.split("/").slice(0, 3).join("/")
  const fullPathName = location.pathname

  return <Menu style={{ width: 256 }} selectedKeys={[pathName, fullPathName]} mode="inline" >
    <Menu.ItemGroup key="message" title="知识库">
      <Menu.Item key="/knowledgeBase">
        <Link to="/knowledgeBase">
          <span><OrderedListOutlined /> 知识库列表</span>
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="/knowledgeBase/contact/KnowledgeBaseListConfig">
        <Link to="/knowledgeBase/contact/KnowledgeBaseListConfig">
          <span><OrderedListOutlined /> 知识库配置</span>
        </Link>
      </Menu.Item> */}
    </Menu.ItemGroup>
    <Menu.ItemGroup key="contact" title="联系人">
      <Menu.Item key="/knowledgeBase/contact/room">
        <Link to="/knowledgeBase/contact/room">
          <span><OrderedListOutlined /> 群</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/knowledgeBase/contact/friend">
        <Link to="/knowledgeBase/contact/friend">
          <span><OrderedListOutlined /> 好友</span>
        </Link>
      </Menu.Item>
    </Menu.ItemGroup>

  </Menu >
}
