import { Table, Divider, Button, Form, Input, Pagination, Modal, Upload, message } from "antd"
import { UploadOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import ButtonGroup from "antd/lib/button/button-group"
import { useSearchParams } from "react-router-dom/dist"

const defaultQuery = { name: '' }

export function KnowledgeBaseList() {
    const [addVisible, setAddVisible] = useState(false)
    const [editVisible, setEditVisible] = useState(false)
    const [searchParams] = useSearchParams()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch()
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState(defaultQuery)
    // 文件上传最大数量
    const [maxCount, setMaxCount] = useState(1)

    const [form] = Form.useForm()
    const [addForm] = Form.useForm()
    const [editForm] = Form.useForm()
    const [addFormErrors, setAddFormErrors] = useState({})
    const [editFormErrors, setEditFormErrors] = useState({})


    dispatch(NavActive("KnowledgeBase"))

    const onAddCancel = () => {
        setAddFormErrors({})
        setAddVisible(false)
    }

    const onEditCancel = () => {
        setEditFormErrors({})
        setEditVisible(false)
    }

    /** 新增完成 */
    const addFinish = () => {
        addForm.validateFields().then((values) => {
            // 手动验证字段
            const errors = {};
            if (!/^[a-zA-Z]+$/.test(values.name) || !values.name) {
                errors.name = "请输入正确的知识库名称"
            }
            if (!values.attachment) {
                errors.attachment = "请选择文件"
            }
            if (!values.remark) {
                errors.remark = "请输入备注"
            }
            // 如果有错误，显示错误消息并中止提交
            if (Object.keys(errors).length > 0) {
                setAddFormErrors(errors);
                return;
            }
            const formData = new FormData()
            formData.append('name', values.name)
            formData.append('file', values.attachment[0].originFileObj)
            formData.append('fileName', values.attachment[0].name)
            formData.append('remark', values.remark)
            jsonRequest.file("/vector/upload", formData)
                .then(response => response.json())
                .then((values) => {
                    handlePageTo(query, page, size)
                    message.success('添加成功')
                })
                .catch(() => {
                    message.error('添加失败')
                })
            addForm.resetFields()
            onAddCancel()
        })
    }

    /** 编辑完成 */
    const editFinish = () => {
        editForm.validateFields().then((values) => {
            // 手动验证字段
            const errors = {};
            if (!values.remark) {
                errors.remark = "请输入备注"
            }
            // 如果有错误，显示错误消息并中止提交
            if (Object.keys(errors).length > 0) {
                setEditFormErrors(errors)
                return
            }
            const attachment = values.attachments.filter((item) => item.status !== 'done')
            const formData = new FormData()
            formData.append('id', editForm.getFieldValue('id'))
            if (attachment.length !== 0) {
                formData.append('file', attachment[0].originFileObj)
                formData.append('fileName', attachment[0].name)
            } else {
                formData.append('file', null)
                formData.append('fileName', null)
            }
            formData.append('remark', values.remark)
            jsonRequest.file("/vector/update", formData)
                .then(response => response.json())
                .then((values) => {
                    handlePageTo(query, page, size)
                    message.success('编辑成功')
                })
                .catch(() => {
                    message.error('编辑失败')
                })
                .catch(() => { })
            editForm.resetFields()
            onEditCancel()
        })
    }

    const handlePageTo = (query, page, size, touchLoading = true) => {
        setQuery(query)
        form.setFieldValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.post("/knowledgeBase/getByName", { page, size, ...query })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data)
                setLoading(false)
            })
            .catch(() => { })
    }

    const handleSearch = (values) => {
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, page, size)
    }

    useEffect(() => {
        // 从urlquery中获取到的查询
        const searchQuery = Object.fromEntries([...searchParams])
        const queryWithParam = { ...query, ...searchQuery }
        handlePageTo(queryWithParam, 1, 20)
    }, [user?.token])

    const handleEdit = (record) => {
        editForm.setFieldsValue({
            ...record,
            attachments: record.attachments.map((item) => ({
                uid: item.id,
                name: item.name,
                status: 'done',
                url: item.name,
            })),
        })
        setEditVisible(true)
        setMaxCount(editForm.getFieldValue('attachments').length + 1)
        console.log(record, editForm.getFieldValue('id'))
    }

    // 删除功能
    const handleDelete = (id) => {
        Modal.confirm({
            centered: true,
            width: 400,
            title: '删除',
            content: '确认删除该知识库吗？',
            onOk: async (close) => {
                try {
                    await jsonRequest.post(`/knowledgeBase/deleteById`, { id })
                    handlePageTo(query, page, size)
                    close()
                    message.success('删除成功')
                } catch (error) {
                    message.error('删除失败')
                }
            },
        })
    }

    // 加入图片前的校验
    const beforeUpload = (file) => {
        const isPassMax = file.size / 1024 / 1024 < 10
        if (!isPassMax) {
            message.error('文件不允许超过10M')
            return Upload.LIST_IGNORE
        }
        return false
    };

    return <div className="page user">
        <h1>
            知识库列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="name" label="知识库名称">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
                <div style={{ marginLeft: 'auto' }}>
                    <Button type="primary" onClick={() => setAddVisible(true)} >添加</Button>
                </div>
            </Form>
        </div >

        <Table dataSource={data.result}
            className="flex-table"
            size="middle"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            <Table.Column align="center"
                width={100}
                title="序号"
                dataIndex="id"
                key="id"
                render={(text, item, index) => (page - 1) * size + (index + 1)}
            />
            <Table.Column align="center"
                width={100}
                title="知识库名称"
                dataIndex="name"
                key="name"
            />
            <Table.Column align="center"
                width={100}
                title="内容"
                dataIndex="attachments"
                key="attachments"
                render={(text) => (
                    text.map((item, index) =>
                    (< div key={index}>
                        {item.name}
                        < br />
                    </div>))
                )}
            />
            < Table.Column align="center"
                width={100}
                title="备注"
                dataIndex="remark"
                key="remark"
            />
            <Table.Column align="center"
                width={150}
                title="操作"
                render={(text, record) => <>
                    <Button type="link" onClick={() => handleEdit(record)}>编辑</Button>
                    <Divider type="vertical" />
                    <Button type="link"
                        onClick={() => handleDelete(record.id)}
                    >删除 <MinusCircleOutlined />
                    </Button>
                </>}
            />
        </Table >

        <Pagination style={{ margin: 5 }}
            total={data.total}
            current={data.page}
            pageSize={data.size}
            showQuickJumper
            showSizeChanger
            onChange={(page, size) => { handlePageTo(query, page, size) }}
        />


        {/* 新增页面 */}
        <Modal
            centered
            title="新增"
            open={addVisible}
            onOk={addFinish}
            onCancel={onAddCancel}
            afterClose={() => addForm.resetFields()}
            width={500}
        >
            <Form form={addForm} name="add" autoComplete="off">
                <div style={{ maxWidth: '300px', marginLeft: "70px", position: 'relative' }} >
                    <Form.Item label="知识库名称" name="name" id="name"
                        validateStatus={addFormErrors.name ? "error" : ""}
                        help={addFormErrors.name}>
                        <Input placeholder="请输入英文名称" allowClear />
                    </Form.Item>

                    <Form.Item label="内容" name="attachment" id='attachment'
                        validateStatus={addFormErrors.attachment ? "error" : ""}
                        help={addFormErrors.attachment}
                        valuePropName="fileList"
                        getValueFromEvent={(e) => (Array.isArray(e) ? e : e.fileList)}>
                        <Upload beforeUpload={beforeUpload} maxCount={1}>
                            <Button icon={<UploadOutlined />}>点击上传文件(一次仅限上传一个文件)</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item label="备注" name="remark" id="remark"
                        validateStatus={addFormErrors.remark ? "error" : ""}
                        help={addFormErrors.remark}>
                        <Input placeholder="请输入备注" allowClear />
                    </Form.Item>
                </div>
            </Form>
        </Modal>

        {/* 编辑页面 */}
        <Modal
            centered
            title="编辑"
            open={editVisible}
            onOk={editFinish}
            onCancel={onEditCancel}
            afterClose={() => editForm.resetFields()}
            width={500}
        >
            <Form form={editForm} name="edit" autoComplete="off">
                <div style={{ maxWidth: '300px', marginLeft: "70px", position: 'relative' }} >
                    <Form.Item label="知识库名称" name="name" id="name" >
                        <Input placeholder="请输入知识库名称" allowClear readOnly />
                    </Form.Item>
                    <Form.Item label="内容" name="attachments" id="attachments" valuePropName="fileList" getValueFromEvent={(e) => (Array.isArray(e) ? e : e.fileList)}>
                        <Upload beforeUpload={beforeUpload}
                            maxCount={maxCount}
                            itemRender={(originNode, file) => {
                                return file.status === 'done' ?
                                    (<div>{file.url}</div>) :
                                    (<>
                                        {originNode}
                                        <div>{file.url}</div>
                                    </>
                                    )
                            }}>
                            <Button icon={<UploadOutlined />}>点击上传文件(一次仅限上传一个文件)</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item label="备注" name="remark" id="remark"
                        validateStatus={editFormErrors.remark ? "error" : ""}
                        help={editFormErrors.remark}>
                        <Input placeholder="请输入备注" allowClear />
                    </Form.Item>
                </div>
            </Form>
        </Modal>

    </div >
}