import React from "react"
import { Menu } from 'antd'
import { useLocation, Link } from "react-router-dom"
import { OrderedListOutlined } from '@ant-design/icons'

export default function SideMenu (props) {
  const location = useLocation()

  const pathName = location.pathname.split("/").slice(0, 3).join("/")
  const fullPathName = location.pathname

  return <Menu style={{ width: 256 }} selectedKeys={[pathName, fullPathName]} mode="inline" >
    <Menu.ItemGroup key="message" title="子账号">
      <Menu.Item key="/subAccount">
        <Link to="/subAccount">
          <span><OrderedListOutlined /> 子账户列表</span>
        </Link>
      </Menu.Item>
    </Menu.ItemGroup>
    <Menu.ItemGroup key="contact" title="联系人">
      <Menu.Item key="/subAccount/contact/room">
        <Link to="/subAccount/contact/room">
          <span><OrderedListOutlined /> 群</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/subAccount/contact/friend">
        <Link to="/subAccount/contact/friend">
          <span><OrderedListOutlined /> 好友</span>
        </Link>
      </Menu.Item>
    </Menu.ItemGroup>

  </Menu >
}