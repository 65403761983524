import { Table, Divider, Button, Form, Input, Modal, message, Switch, Pagination, Tooltip } from "antd"
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import { Select } from "antd"
import ButtonGroup from "antd/lib/button/button-group"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { SearchOutlined, SettingTwoTone } from "@ant-design/icons"
import { ViewAccountType } from "../components/ViewAccountType"
import { useSearchParams } from "react-router-dom/dist"
import { ProfileRequestForm } from "../components/ProfileRequestForm"
import { ProfilePromptMessageForm } from "../components/ProfilePromptMessageForm"
import { useLocation } from 'react-router-dom'

// import QueryForm from "./components/QueryForm"

const defaultQuery = { id: "", accountId: "", username: "", roomId: "" }
const defaultQueryOne = { accountId: "", username: "" }

export function SubAccountRoomList (props) {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [dataThis, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const user = useSelector(selectUser)
  const jsonRequest = useSelector(selectJsonRequest)
  const dispatch = useDispatch()
  // 页
  const [page, setPage] = useState(1)
  // 数
  const [size, setSize] = useState(20)
  // 搜索参数
  const [query, setQuery] = useState(defaultQuery)
  const [form] = Form.useForm()
  const [formRoom] = Form.useForm()
  const [formErrors, setFormErrors] = useState({})
  const [onAddVisible, setVisit] = useState(false)
  const [initialBoolean, setInitialBoolean] = useState(false)

  //定义一个数组来存储子账户
  //定义一个数组来存储所有的群列表
  const [accountSelectValue, setAccountSelectValue] = useState({ accountId: null })
  const [listSelectValue, setListSelectValue] = useState({ roomID: null, id: null })
  const [subAccountSelectValue, setSubAccountSelectValue] = useState({ username: null, id: null })
  const [subAccountSelectOptions, setSubAccountSelectOptions] = useState([])
  const [accountSelectOptions, setAccountSelectOptions] = useState([])
  const [listSelectOptions, setListSelectOPtions] = useState([])
  const [resetForm, setResetForm] = useState()

  const onAddCancel = () => {
    setAccountSelectValue('')
    setSubAccountSelectValue('')
    setListSelectValue('')
    setSubAccountSelectOptions('')
    setListSelectOPtions('')
    formRoom.resetFields()
    formRoom.setFields([])
    setFormErrors({})
    setAccountSelectOptions('')
    setVisit(false)
  }

  //打开实现子账号实现下拉列表
  const onAddSelect = () => {
    haveInitiate()
    openAccountOptions()
    setVisit(true)
  }

  //实现添加
  const onAddFinish = () => {
    formRoom.validateFields().then((values) => {
      // 手动验证字段
      const errors = {}
      if (!values.accountId) {
        errors.accountId = "请选择主账号"
      }
      if (!values.id) {
        errors.id = "请选择子账号"
      }
      if (!values.roomID) {
        errors.roomID = "请选择子群"
      }
      // 如果有错误，显示错误消息并中止提交
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors)
        return
      }
      const sameAccount = { accountId: values.accountId, terminalId: parseInt(values.id), roomId: values.roomID }
      jsonRequest.post("/account/room/terminal-add", { ...sameAccount })
        .then(response => response.json())
        .then(({ data }) => {
          handlePageTo(query, page, size)
          message.success('添加成功')
        })
      onAddCancel()
    })

  }

  dispatch(NavActive("SubAccount"))

  // 从urlquery中获取到的查询
  useEffect(() => {
    const searchQuery = Object.fromEntries([...searchParams])
    if (searchQuery.accountId !== undefined) {
      const queryWithParam = { ...query, accountId: searchQuery.accountId, id: searchQuery.id, username: searchQuery.username }
      handlePageTo(queryWithParam, 1, 20)
    }
    else {
      handlePageTo(query, 1, 20)
    }
  }, [user?.token])

  //页面跳转
  const handlePageTo = (query, page, size, touchLoading = true) => {
    setData('')
    // setAccountSelectValue(query.accountID)
    setQuery(query)
    form.setFieldsValue(query)
    if (!!touchLoading) setLoading(true)
    setPage(page)
    setSize(size)
    jsonRequest.get("/account/room/terminal-list", { page, size, ...query })
      .then(response => response.json())
      .then(({ data }) => {
        if (data !== undefined) {
          setData(data)
          setLoading(false)
          haveInitiate()
          console.log(data)
        }
      })
      .catch(() => { })
  }

  //设立初始值
  const haveInitiate = () => {
    {
      if (query.accountId !== null && query.accountId !== "" && query.id !== null
        && query.username !== null && query.id !== "" & query.username !== "") {
        setInitialBoolean(true)
        const accountInitiate = { accountId: query.accountId }
        setAccountSelectValue(accountInitiate)
        console.log("主账户已经设置初始值")
        const subAccountInitiate = { id: query.id, username: query.username }
        setSubAccountSelectValue({ ...subAccountSelectValue, ...subAccountInitiate })
        console.log("子账户已经设置初始值")
        openAccountOptions()
        openSubAccountOptions()
      }
      else {
        console.log('未成功设置初始值')
      }
    }
  }

  //点击呢称跳转
  const handleSearch = (values) => {
    // setAccountSelectValue({ ...accountSelectValue, accountID: values.accountID })
    setQuery({ ...query, ...values })
    handlePageTo({ ...query, ...values }, page, size)
  }

  //在选择后主账号后改变值
  const handleAccountSelectChange = (value) => {
    setAccountSelectValue({ accountId: value })
    formRoom.setFieldsValue({
      accountId: value,
      id: null,
      roomID: null
    })
    openSubAccountOptions()
    setListSelectOPtions([])
  }

  //在选择子账号时改变其值
  const handleSubAccountSelectChange = (value) => {
    setSubAccountSelectValue({ id: value })
    formRoom.setFieldsValue({
      accountId: accountSelectValue.accountId,
      id: value,
      roomID: null
    })
    changeAccount()
  }

  //改变主下拉列表
  const openAccountOptions = () => {
    jsonRequest.get("/account/list", {})
      .then(response => response.json())
      .then(({ data }) => {
        const accountNames = data.map(item => ({
          accountId: item.id
        }))
        setAccountSelectOptions(accountNames)
      })
      .catch(() => { })
  }

  //改变子下拉列表
  const openSubAccountOptions = () => {
    if (formRoom.getFieldValue('accountId') !== "" && formRoom.getFieldValue('accountId') !== null) {
      jsonRequest.get("/sub-account/list", { accountID: formRoom.getFieldValue('accountId') })
        .then(response => response.json())
        .then(({ data }) => {
          const accountNames = data.map(item => {
            const { id, username } = item
            return { id, username }
          }
          )
          setSubAccountSelectOptions(accountNames)
        })
        .catch(() => { })
    }
    else {
      setSubAccountSelectOptions('')
    }
  }

  //改变群下拉列表
  const changeAccount = () => {
    {
      if (formRoom.getFieldValue('id') !== null && formRoom.getFieldValue('id') !== undefined &&
        formRoom.getFieldValue('accountId') !== "" && formRoom.getFieldValue('accountId') !== null) {
        jsonRequest.get("/account/room/list", { accountID: formRoom.getFieldValue('accountId'), terminalID: formRoom.getFieldValue('id') })
          .then(response => response.json())
          .then(({ data }) => {
            const accountNames = data.map(item => ({ roomID: item.roomID, id: item.id }))
            setListSelectOPtions(accountNames)
          })
          .catch(() => { })
      }
      else {
        setListSelectOPtions('')
      }
    }
  }

  //在主账号变了的时候改变子列表
  useEffect(() => {
    if (!initialBoolean) {
      setSubAccountSelectValue('')
    }
    setInitialBoolean(false)
    openSubAccountOptions()
  }, [accountSelectValue])

  // 在子账号变化的时候改变群
  useEffect(() => {
    setListSelectValue('')
    changeAccount()
  }, [subAccountSelectValue])

  const handleRequestProfileModel = (accountType, accountID, roomName,username) => {
    let formInstance = null
    jsonRequest.get("/account/profile/request/setting-map", { accountType, accountID, ownerType: "bot_room", ownerID: roomName,username })
      .then(response => response.json())
      .then(({ data }) => {
        Modal.confirm({
          width: 1200,
          title: `群参数设置 - ${roomName}`,
          content: <ProfileRequestForm formRef={(instance) => formInstance = instance} initialValues={data} />,
          onOk: (close) => {
            formInstance
              .validateFields()
              .then((v) => {
                jsonRequest.post("/account/profile/request/setting-map", { accountType, accountID, ownerType: "bot_room", ownerID: roomName,username, param: v })
                  .then(response => response.json())
                  .then(({ data }) => {
                    message.success("设置成功")
                    close()
                  })
              })
              .catch(info => {
                console.log('Validate Failed:', info)
              })
            return false
          }
        })
      })
  }

  const handleProfilePromptModel = (roomID, roomName) => {
    let formInstance = null
    jsonRequest.get("/account/profile/prompt/setting", { ownerType: "bot_room", ownerID: roomID })
      .then(response => response.json())
      .then(({ data }) => {
        Modal.confirm({
          width: 1200,
          title: `系统提示设置 - ${roomName}`,
          content: <ProfilePromptMessageForm formRef={(instance) => formInstance = instance} initialValues={dataThis} />,
          onOk: (close) => {
            formInstance
              .validateFields()
              .then((v) => {
                jsonRequest.post("/account/profile/prompt/setting", { ownerType: "bot_room", ownerID: roomID, ...v })
                  .then(response => response.json())
                  .then(({ data }) => {
                    message.success("设置成功")
                    close()
                  })
              })
              .catch(info => {
                console.log('Validate Failed:', info)
              })
            return false
          }
        })
      })
  }

  //删除请求
  const handleProfileDeleteModel = (id) => {
    jsonRequest.delete(`/account/room/terminal-delete/${id}`)
      .then(response => {
        if (response.ok) {
          console.log("DELETE request successful")
          handlePageTo(query, page, size)
        } else {
          console.error("DELETE request failed")
        }
      })
      .catch(error => {
        console.error("Error during DELETE request:", error)
      })

  }

  //删除提示框
  const showConfirm = (id) => {
    Modal.confirm({
      title: '确认框',
      content: '确定删除该群吗',
      onOk () {
        handleProfileDeleteModel(id)
      },
      onCancel () {
      },
    })
  }
  // 终端设置请求参数
  const handleProfileTerminalSet = (accountType, accountID, roomTopic, field, value) => {
    jsonRequest.post(`/account/room/terminal/${field}`, { accountType, accountID, roomTopic, value })
      .then(response => response.json())
      .then(({ data }) => {
        // handlePageTo(query, page, size)
      })
  }

  return <div className="page user">
    <Modal
      centered
      title="新增子群"
      open={onAddVisible}
      onOk={onAddFinish}
      onCancel={onAddCancel}
      afterClose={() => formRoom.resetFields()}
      width={500}
    >
      <Form form={formRoom}>
        <div style={{ maxWidth: '300px', marginLeft: "70px", position: 'relative' }} >
          <Form.Item label="主账号ID" name="accountId" initialValue={accountSelectValue.accountId}
            validateStatus={formErrors.accountId ? "error" : ""}
            help={formErrors.accountId}>
            <Select
              placeholder="请选择要添加的主账户"
              onChange={handleAccountSelectChange}
              options={Array.isArray(accountSelectOptions) ? accountSelectOptions.map(option => ({
                label: option.accountId,
                value: option.accountId,
                key: option.accountId
              })) : []}
            />
          </Form.Item>
          <Form.Item label="子账号ID" name="id" initialValue={subAccountSelectValue.id}
            validateStatus={formErrors.id ? "error" : ""}
            help={formErrors.id}>
            <Select
              placeholder="请选择要添加的子账户"
              onChange={handleSubAccountSelectChange}
              options={Array.isArray(subAccountSelectOptions) ? subAccountSelectOptions.map(option => ({
                label: option.username,
                value: option.id,
                key: option.id
              })) : []}
            />
          </Form.Item>
          <Form.Item label="群列表" name="roomID"
            validateStatus={formErrors.roomID ? "error" : ""}
            help={formErrors.roomID}>
            <Select
              placeholder="请选择要添加的群"
              options={Array.isArray(listSelectOptions) ? listSelectOptions.map(option => ({
                label: option.roomID,
                value: option.id,
                key: option.id
              })) : []}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>

    <h1>
      群列表
      <Divider type="vertical" />
    </h1>

    <div className="search-bar">
      <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
        <Form.Item name="accountId" label="主账户昵称">
          <Input placeholder="模糊查询" allowClear></Input>
        </Form.Item>
        <Form.Item name="username" label="子账户昵称">
          <Input placeholder="模糊查询" allowClear></Input>
        </Form.Item>
        <Form.Item name="roomId" label="群名">
          <Input placeholder="模糊查询" allowClear></Input>
        </Form.Item>
        <ButtonGroup>
          <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
          <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
        </ButtonGroup>
        <div style={{ marginLeft: 'auto', marginRight: '30px' }}>
          <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => onAddSelect()}>添加</Button>
        </div>
      </Form>
    </div >
    <Table dataSource={dataThis.data}
      className="flex-table"
      size="middle"
      loading={loading}
      pagination={false} rowKey={d => d.id}>
      {/* <Table.Column align="center" width={100} title="所属账号类型" dataIndex="accountType" key="accountType" render={(field) => <ViewAccountType type={field} />} /> */}
      <Table.Column align="center" width={100} title="主账号昵称" dataIndex="accountId" key="accountId" render={(text, item) => <Link onClick={() => handleSearch({ accountId: item.accountId })}>{text} <SearchOutlined /></Link>} />
      <Table.Column align="center" width={200} title="群名或昵称" dataIndex="topic" key="topic" render={(text, item) => <Link onClick={() => handleSearch({ roomId: item.roomId })}>{text} <SearchOutlined /></Link>} />
      <Table.Column align="center" width={100} title="子账号昵称" dataIndex="username" key="username" render={(text, item) => <Link onClick={() => handleSearch({ username: item.username })}>{text} <SearchOutlined /></Link>} />
      <Table.Column align="center" width={100} title="群成员总数" dataIndex="raw" key="raw" render={(field) => field?.memberIdList?.length} />
      <Table.Column align="center" width={150} title="信息更新时间" dataIndex="updatedAt" key="updatedAt" render={(field, item) => dayjs(field || item.insertedAt).format("YYYY-MM-DD HH:mm")} />
      <Table.Column align="center" width={100} title="终端启用" dataIndex={["profile", "terminal", "enable"]} key={["profile", "terminal", "enable"]} render={(field, item) => <Switch checkedChildren="启用" unCheckedChildren="不启用" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.accountType, item.accountId, item.topic, "enable", enable) }} />} />
      <Table.Column align="center" width={100} title="终端设置请求参数" dataIndex={["profile", "terminal", "profile_setting_allow"]} key={["profile", "terminal", "profile_setting_allow"]} render={(field, item) => <Switch checkedChildren="允许" unCheckedChildren="禁止" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.accountType, item.accountId, item.topic, "profile_setting_allow", enable) }} />} />

      <Table.Column align="center" width={400} title="操作" render={(field, item) => <>
        <Button type="link" onClick={() => handleRequestProfileModel(item.accountType, item.accountId, item.topic,item.username)}>AI请求参数 <SettingTwoTone /></Button>
        <Divider type="vertical" />
        <Button type="link" onClick={() => handleProfilePromptModel(item.id, item.topic)}>提示 <SettingTwoTone /></Button>
        <Divider type="vertical" />
        <Link target="_blank" to={`/account/message?accountType=${item.accountType}&accountID=${item.accountId}&roomName=${item.topic}`}>消息历史 <SearchOutlined /></Link>
        <Divider type="vertical" />
        <Button type="link" onClick={() => showConfirm(item.id)}>删除<MinusCircleOutlined /></Button>
      </>} />
    </Table>
    <Pagination style={{ margin: 5 }} total={dataThis.total} current={dataThis.page} pageSize={dataThis.size} showQuickJumper onChange={(page, size) => { handlePageTo(query, page, size) }} />
  </div >
}