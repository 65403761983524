import { Table, Divider, Button, Form, Input, Modal, message, Pagination, Select } from "antd"
import { useEffect, useState } from "react"
import { MinusCircleOutlined, SettingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import ButtonGroup from "antd/lib/button/button-group"
import { SearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { useSearchParams } from "react-router-dom/dist"

const defaultQuery = { accountId: "", contactName: "" }

export function KnowledgeBaseFriendList() {
    const [form] = Form.useForm()
    const [addForm] = Form.useForm()
    const [addVisible, setAddVisible] = useState(false)
    const [addFormErrors, setAddFormErrors] = useState({})

    const [editForm] = Form.useForm()
    const [editVisible, setEditVisible] = useState(false)
    const [editFormErrors, setEditFormErrors] = useState({})
    // 是否可以选择好友
    const [isSelect, setIsSelect] = useState(true)

    const [searchParams] = useSearchParams()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch()
    // 从 URL 查询参数中获取值
    const accountID = searchParams.get("accountID")
    const id = searchParams.get("id")
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState(defaultQuery)
    // 所有数据库数据
    const [knowledgeBaseData, setKnowledgeBaseData] = useState([])
    // 获取所有的主账号
    const [accountIdData, setAccountIdData] = useState([])
    // 获取主账号对应的好友
    const [friendData, setFriendData] = useState([])

    //dispatch 函数用来触发一个 action
    dispatch(NavActive("KnowledgeBase"))

    /** 获取所有的知识库 */
    const getKnowledgeBaseData = () => {
        jsonRequest.get("/knowledgeBase/getAllName")
            .then(response => response.json())
            .then(({ data }) => {
                setKnowledgeBaseData(
                    data.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }))
                )
            })
            .catch(() => { })
    }

    /** 获取所有的主账号 */
    const getAccountIdData = () => {
        jsonRequest.get("/account/list")
            .then(response => response.json())
            .then(({ data }) => {
                setAccountIdData(
                    data.map((item) => ({
                        value: item.id,
                        label: item.id,
                    }))
                )
            })
            .catch(() => { })
    }


    //查询功能（路由传参）
    useEffect(() => {
        // 从urlquery中获取到的查询
        const searchQuery = Object.fromEntries([...searchParams])
        const queryWithParam = { ...query, ...searchQuery }
        handlePageTo(queryWithParam, 1, 20)
        getKnowledgeBaseData()
        getAccountIdData()
    }, [user?.token])

    //获取基本页面
    const handlePageTo = (query, page, size, touchLoading = true) => {
        setQuery(query)
        form.setFieldsValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.get("/baseMessage/contact-base/list", { page, size, ...query })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data)
                setLoading(false)
            })
            .catch(() => { })
    }

    /** 获取当前主账号的所有好友 */
    const getFriendData = (accountID) => {
        jsonRequest.get("/account/contact/contactListBy", { accountID })
            .then(response => response.json())
            .then((value) => {
                console.log(value);
                let data1 = [...value.data];
                let result = data.result;
                value.data.forEach((item) => {
                    result.forEach((i) => {
                      if (item.contactID === i.nickName && accountID === i.accountId) {
                        let index = data1.indexOf(item);
                        if (index !== -1) {
                            data1.splice(index, 1);
                        }
                      }
                    });
                  });
                setFriendData(
                    data1.map((item) => ({
                        value: item.id,
                        label: item.contactID,
                    }))
                )
            })
            .catch(() => { })
    }

    // 取消新增
    const addCancel = () => {
        setAddFormErrors({})
        setAddVisible(false)
        setIsSelect(true)
    }

    const addFinish = () => {
        addForm.validateFields().then((values) => {
            // 手动验证字段
            const errors = {}
            if (!values.accountID) {
                errors.accountID = "请选择主账号"
            }
            if (!values.friend) {
                errors.friend = "请选择好友"
            }
            if (!values.knowledgeName) {
                errors.knowledgeName = "请选择知识库"
            }
            // 如果有错误，显示错误消息并中止提交
            if (Object.keys(errors).length > 0) {
                setAddFormErrors(errors)
                return
            }
            const baseID = values.knowledgeName
            const ownerID = values.friend
            const enable = true
            const ownerType = 'bot_contact'
            jsonRequest.post("/vector/setting", { accountID: values.accountID, baseID, enable, ownerType, ownerID })
                .then(response => response.json())
                .then(() => {
                    handlePageTo(query, page, size)
                    message.success('添加成功')
                })
                .catch(() => { })
            addForm.resetFields()
            addCancel()
            setIsSelect(true)
        })
    }

    // 查看完成
    const onLookFinish = () => {
        editForm.validateFields().then((values) => {
            // 手动验证字段
            const errors = {};
            if (!values.knowledgeName) {
                errors.knowledgeName = "请选择知识库"
            }
            // 如果有错误，显示错误消息并中止提交
            if (Object.keys(errors).length > 0) {
                setEditFormErrors(errors)
                return
            }
            // 需要做一个类型判断
            const baseID = typeof values.knowledgeName === 'number' ? values.knowledgeName :
                knowledgeBaseData.filter(item => item.label === values.knowledgeName)[0].value
            const id = parseInt(editForm.getFieldValue('id'))
            jsonRequest.post("/baseMessage/updateById", { id: id, baseID: baseID })
                .then(response => response.json())
                .then(() => {
                    handlePageTo(query, page, size)
                    message.success('配置成功')
                })
                .catch(() => {
                    message.error('配置失败')
                })
            editForm.resetFields()
            onLookCancel()
        })
    }
    
    const onLookKnowledge = (record) => {
        editForm.setFieldsValue({ ...record })
        setEditVisible(true)
    }
    // 取消查看配置界面
    const onLookCancel = () => {
        setEditFormErrors({})
        setEditVisible(false)
    }

    // 删除功能
    const handleDelete = (id) => {
        Modal.confirm({
            centered: true,
            width: 400,
            title: '删除',
            content: '确认删除该账号吗？',
            onOk: async (close) => {
                try {
                    await jsonRequest.post(`/baseMessage/deleteById`, { id })
                    handlePageTo(query, page, size)
                    close()
                    message.success('删除成功')
                } catch (error) {
                    message.error('删除失败')
                }
            },
        })
    }

    //搜索功能
    const handleSearch = (values) => {
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, page, size)
    }

    return <div className="page user">
        <h1>
            好友列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={{ query }} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="accountId" label="账号ID(昵称)">
                    <Input placeholder="模糊查询" allowClear ></Input>
                </Form.Item>
                <Form.Item name="contactName" label="好友ID">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
                <div style={{ marginLeft: 'auto' }}>
                    <Button type="primary" onClick={() => setAddVisible(true)} >添加</Button>
                </div>
            </Form>
        </div>

        <Table dataSource={data.result}
            size="middle"
            className="flex-table"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            <Table.Column align="center" width={150} title="所属账号昵称" dataIndex="accountId" key="accountId" render={(text, item) => <Link onClick={() => handleSearch({ accountType: item.accountType, accountId: text, id, accountID })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={120} title="好友ID(昵称)" dataIndex="nickName" key="nickName" />
            <Table.Column align="center" width={120} title="更新时间" dataIndex="updatedAt" key="updatedAt" />
            <Table.Column align="center" width={120} title="知识库" dataIndex="knowledgeName" key="knowledgeName" />
            <Table.Column align="center" width={120} title="操作" render={(field, item) => <>
                <Button type="link" onClick={() => onLookKnowledge(item)}>配置<SettingOutlined /></Button>
                <Divider type="vertical" />
                <Button type="link"
                    onClick={() => handleDelete(item.id)}
                >删除 <MinusCircleOutlined /></Button>
            </>} />
        </Table>
        <Pagination style={{ margin: 5 }} total={data.total} current={data.page} pageSize={data.size} showQuickJumper onChange={(page, size) => { handlePageTo(query, page, size) }} />


        <Modal
            centered
            title="知识库配置"
            open={editVisible}
            onOk={onLookFinish}
            onCancel={onLookCancel}
            afterClose={() => editForm.resetFields()}
            width={500}
        >
            <Form form={editForm} style={{ maxWidth: '300px', marginLeft: "70px" }} >
                <Form.Item label="好友名：" name="nickName">
                    <Input readOnly></Input>
                </Form.Item>
                <Form.Item label="知识库：" name="knowledgeName"
                    validateStatus={editFormErrors.knowledgeName ? "error" : ""}
                    help={editFormErrors.knowledgeName}>
                    <Select
                        allowClear
                        placeholder="选择数据库"
                        options={knowledgeBaseData}
                    />
                </Form.Item>
            </Form>
        </Modal>

        <Modal
            centered
            title="新增"
            open={addVisible}
            onOk={addFinish}
            onCancel={addCancel}
            afterClose={() => {
                addForm.resetFields()
                setIsSelect(true)
            }}
            width={500}
        >
            <Form form={addForm} style={{ maxWidth: '300px', marginLeft: "70px" }} >
                <Form.Item label="主账号：" name="accountID"
                    validateStatus={addFormErrors.accountID ? "error" : ""}
                    help={addFormErrors.accountID}>
                    <Select
                        allowClear
                        placeholder="请选择主账号"
                        options={accountIdData}
                        onChange={(value) => {
                            addForm.resetFields(['friend'])
                            getFriendData(value)
                            setIsSelect(false)
                        }}
                    />
                </Form.Item>
                <Form.Item label="好友：" name="friend"
                    validateStatus={addFormErrors.friend ? "error" : ""}
                    help={addFormErrors.friend}>
                    <Select
                        allowClear
                        placeholder="请选择好友"
                        options={friendData}
                        disabled={isSelect}
                    />
                </Form.Item>
                <Form.Item label="知识库：" name="knowledgeName"
                    validateStatus={addFormErrors.knowledgeName ? "error" : ""}
                    help={addFormErrors.knowledgeName}>
                    <Select
                        allowClear
                        placeholder="请选择数据库"
                        options={knowledgeBaseData}
                    />
                </Form.Item>
            </Form>
        </Modal>
    </div>

}