import { Divider, Form, Input, InputNumber, Select, Slider, Switch } from "antd"
import { useState } from "react"

export function ProfilePromptMessageForm({ formRef, initialValues, style }) {
    return <Form ref={formRef} size="small" autoComplete="off" initialValues={initialValues} style={{ display: "flex", ...style }} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
        <div style={{ width: "40%" }}>
            <Form.Item name="system" label="系统提示" >
                <Input.TextArea autoSize />
            </Form.Item>
        </div>
        <div style={{ width: "60%" }}>
            <Form.Item name="messages" label="文档">
                <Input.TextArea autoSize style={{minHeight: "3em", maxHeight: "60vh"}} />
            </Form.Item>
        </div>
    </Form>
}