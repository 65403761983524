import React from "react";
import TimeAgo from "react-timeago"
import frenchStrings from './local_time_ago_zh'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { Tooltip } from "antd";
import dayjs from "dayjs";
const formatter = buildFormatter(frenchStrings)

export function LocalTimeAgo(props) {
    return <Tooltip title={dayjs(props.date).format("YYYY-MM-DD HH:mm")}>
        <TimeAgo formatter={formatter} maxPeriod={60} {...props} date={props.date} />
    </Tooltip>;
}