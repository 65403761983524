import { Table, Divider, Button, Form, Input, Modal, message, Switch, Pagination } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import { Select } from "antd"
import ButtonGroup from "antd/lib/button/button-group"
import { ViewAccountType } from "../components/ViewAccountType"
import { Link } from "react-router-dom"
import { useSearchParams } from "react-router-dom/dist"
import { SearchOutlined } from "@ant-design/icons"
// import QueryForm from "./components/QueryForm"

const defaultQuery = { accountId: "", username: "", accountType: "WECHAT" }

export function SubAccountList() {
    const [onAddVisible, setVisit] = useState(false)
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [data, setData] = useState({})
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch()
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 从 URL 查询参数中获取 accountId 的值
    const accountId = searchParams.get("accountId")
    // 搜索参数
    const [query, setQuery] = useState({ accountId: accountId || "", username: "", accountType: "WECHAT" })
    //主账号下拉框
    const [accountOptions, setAccountOptions] = useState([])
    const [formErrors, setFormErrors] = useState({});
    //dispatch 函数用来触发一个 action
    dispatch(NavActive("SubAccount"))

    // 新增弹窗
    const resetForm = () => {
        form1.resetFields();
        setFormErrors({});
    };

    const onAddCancel = () => {
        resetForm(); // 重置表单和错误信息
        setVisit(false)
    }

    const onAddSelect = () => {
        setVisit(true)
    }

    //查询功能（路由传参）
    useEffect(() => {
        // 从urlquery中获取到的查询
        const searchQuery = Object.fromEntries([...searchParams])
        const queryWithParam = { ...query, ...searchQuery }
        handlePageTo(queryWithParam, 1, 20)
    }, [user?.token])

    //获取基本页面
    const handlePageTo = (query, page, size, touchLoading = true) => {
        setData("")
        setQuery(query)
        form.setFieldsValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.get("/sub-account", { page, size, ...query })
            .then(response => response.json())
            .then(({ data }) => {
                if (data !== undefined) {
                    setData(data)
                    setLoading(false)
                    console.log(data.data)
                }
            })
            .catch(() => { })
    }

    //搜索功能
    const handleSearch = (values) => {
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, page, size)
    }

    //新增子账号
    const onAddFinish = () => {
        form1.validateFields().then((values) => {
            // 手动验证字段
            const errors = {};
            if (!values.accountId) {
                errors.accountId = "请选择主账号";
            }
            if (!values.username) {
                errors.username = "请输入子账号用户名";
            }
            if (!values.passwordEncrypted) {
                errors.passwordEncrypted = "请输入密码";
            }
            // 如果有错误，显示错误消息并中止提交
            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                return;
            }
            jsonRequest.post("/sub-account/add", { ...values })
                .then((response) => response.json())
                .then(() => {
                    setQuery(defaultQuery);
                    handlePageTo(query, page, size);
                    message.success("添加成功");
                })
                .catch(() => {
                    message.error("删除失败");
                });
            form1.resetFields();
            onAddCancel();
        });
    };

    //获得主账号（主账号下拉框）
    const account = () => {
        jsonRequest.get("/account/list")
            .then(response => response.json())
            .then(({ data }) => {
                const accountIds = data.map(account => account.id)
                setAccountOptions(accountIds)
            })
            .catch(() => { })
    }

    // 删除功能
    const handleDelete = (id) => {
        Modal.confirm({
            centered: true,
            width: 400,
            title: `删除`,
            content: '确认删除该子账号吗？',
            //调用回调函数
            onOk: async (close) => {
                try {
                    const intId = parseInt(id, 10)
                    await jsonRequest.delete(`/sub-account/delete/${intId}`)
                    handlePageTo(query, page, size)
                    close()
                    message.success('删除成功')
                } catch (error) {
                    message.error('删除失败')
                }
            },
        })
    }

    // 终端设置请求参数
    const handleProfileTerminalSet = (id, field, value) => {
        jsonRequest.post(`/sub-account/terminal-sub/${field}`, { id, value })
            .then(response => response.json())
            .then(({ data }) => {
                // handlePageTo(query, page, size)
            })
    }

    return <div className="page user">
        <h1>
            子账号列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="accountId" label="主账号" >
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="username" label="用户名（子账号）">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="accountType" label="类型">
                    <Select style={{ minWidth: 120 }}>
                        <Select.Option value="">全部</Select.Option>
                        <Select.Option value="WECHAT">微信</Select.Option>
                        <Select.Option value="QQ">QQ</Select.Option>
                    </Select>
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
                <div style={{ marginLeft: 'auto' }}>
                    <Button type="primary" onClick={() => {
                        onAddSelect()
                        account()
                    }} >添加</Button>
                </div>
            </Form>
        </div>

        <Table dataSource={data.data}
            size="middle"
            className="flex-table"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            <Table.Column align="center" width={100} title="主账号" dataIndex="accountId" key="accountId" render={(text, item) =>
                <Link onClick={() => handleSearch({ accountId: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={100} title="账号类型" dataIndex="accountType" key="accountType" render={(field) =>
                <ViewAccountType type={field} />} />
            <Table.Column align="center" width={100} title="子账号" dataIndex="username" key="username" render={(text, item) =>
                <Link onClick={() => handleSearch({ username: text })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={100} title="是否启用" dataIndex={["profile", "terminal", "enable"]} key={["profile", "terminal", "enable"]} render={(field, item) =>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.id, "enable", enable) }} />} />
            <Table.Column align="center" width={100} title="终端设置请求参数" dataIndex={["profile", "terminal", "profile_setting_allow"]} key={["profile", "terminal", "profile_setting_allow"]} render={(field, item) =>
                <Switch checkedChildren="允许" unCheckedChildren="禁止" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.id, "profile_setting_allow", enable) }} />} />
            <Table.Column align="center" width={300} title="操作" render={(field, item) => <>
                <Divider type="vertical" />
                <Link target="_blank" to={`/subAccount/contact/room?accountType=${item.type}&accountId=${item.accountId}&username=${item.username}&id=${item.id}`}>群列表 <SearchOutlined /></Link>
                <Divider type="vertical" />
                <Link target="_blank" to={`/subAccount/contact/friend?accountId=${item.accountId}&username=${item.username}&id=${item.id}`}>好友列表 <SearchOutlined /></Link>
                <Divider type="vertical" />
                <Button type="link" onClick={() => handleDelete(item.id)}>删除</Button>
            </>} />
        </Table>
        <Pagination style={{ margin: 5 }} total={data.total} current={data.page} pageSize={data.size} showQuickJumper onChange={(page, size) => { handlePageTo(query, page, size) }} />

        <Modal
            centered
            title="新增"
            open={onAddVisible}
            onOk={onAddFinish}
            onCancel={onAddCancel}
            afterClose={() => form1.resetFields()}
            width={500}
        >
            <Form form={form1} initialValues={{ accountType: "wechat" }}>
                <div style={{ maxWidth: '300px', marginLeft: "70px", position: 'relative' }} >
                    <Form.Item label="账号类型" name="accountType" id='accountType' style={{ position: 'absolute', top: '-9999px' }}>
                        <Input type="hidden" defaultValue="wechat" />
                    </Form.Item>
                    <Form.Item
                        label="主账号"
                        name="accountId"
                        id='accountId'
                        initialValue={accountId}
                        validateStatus={formErrors.accountId ? "error" : ""}
                        help={formErrors.accountId}
                    >
                        <Select
                            placeholder="请选择主账号"
                            options={accountOptions.map(option => ({
                                label: option,
                                value: option,
                                key: option
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label="子账号"
                        name="username"
                        id='username'
                        validateStatus={formErrors.username ? "error" : ""}
                        help={formErrors.username}
                    >
                        <Input placeholder="请输入子账号用户名" allowClear />
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="passwordEncrypted"
                        id='passwordEncrypted'
                        validateStatus={formErrors.passwordEncrypted ? "error" : ""}
                        help={formErrors.passwordEncrypted}
                    >
                        <Input placeholder="请输入密码" allowClear />
                    </Form.Item>
                </div>
            </Form>
        </Modal>

    </div>

}