import { Table, Divider, Button, Form, Input, Modal, message, Pagination, Select, Switch } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavActive, selectJsonRequest, selectUser } from "../features/Global"
import ButtonGroup from "antd/lib/button/button-group"
import { MinusCircleOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { useSearchParams } from "react-router-dom/dist"

import dayjs from "dayjs"

const defaultQuery = { accountId: "", roomName: "" }

export function KnowledgeBaseRoomList() {

    const [editVisible, setEditVisible] = useState(false)
    const [addVisible, setAddVisible] = useState(false)

    const [form] = Form.useForm()
    const [editForm] = Form.useForm()
    const [addForm] = Form.useForm()
    const [addFormErrors, setAddFormErrors] = useState({})
    const [editFormErrors, setEditFormErrors] = useState({})
    // 是否可以选择群
    const [isSelect, setIsSelect] = useState(true)

    const [searchParams] = useSearchParams()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const user = useSelector(selectUser)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch()
    // 从 URL 查询参数中获取值
    const accountID = searchParams.get("accountID")
    const id = searchParams.get("id")
    // 页
    const [page, setPage] = useState(1)
    // 数
    const [size, setSize] = useState(20)
    // 搜索参数
    const [query, setQuery] = useState(defaultQuery)
    // 所有数据库数据
    const [knowledgeBaseData, setKnowledgeBaseData] = useState([])
    // 获取所有的主账号
    const [accountIdData, setAccountIdData] = useState([])
    // 获取主账号对应的群
    const [roomData, setRoomData] = useState([])

    //dispatch 函数用来触发一个 action
    dispatch(NavActive("KnowledgeBase"))

    /** 获取所有的知识库 */
    const getKnowledgeBaseData = () => {
        jsonRequest.get("/knowledgeBase/getAllName")
            .then(response => response.json())
            .then(({ data }) => {
                setKnowledgeBaseData(
                    data.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }))
                )
            })
            .catch(() => { })
    }

    /** 获取所有的主账号 */
    const getAccountIdData = () => {
        jsonRequest.get("/account/list")
            .then(response => response.json())
            .then(({ data }) => {
                setAccountIdData(
                    data.map((item) => ({
                        value: item.id,
                        label: item.id,
                    }))
                )
            })
            .catch(() => { })
    }

    /** 获取当前主账号的所有群 */
    const getRoomData = (accountID) => {
        jsonRequest.get("/account/room/roomListBy", { accountID })
            .then(response => response.json())
            .then((value) => {
                let data1 = [...value.data];
                let result = data.result;
                value.data.forEach((item) => {
                    result.forEach((i) => {
                      if (item.roomID === i.roomName && accountID === i.accountId) {
                        let index = data1.indexOf(item);
                        if (index !== -1) {
                            data1.splice(index, 1);
                        }
                      }
                    });
                  });
                setRoomData(
                    data1.map((item) => ({
                        value: item.id,
                        label: item.roomID,
                    }))
                )
            })
            .catch(() => { })
    }

    //查询功能（路由传参）
    useEffect(() => {
        // 从urlquery中获取到的查询
        //跳转
        const searchQuery = Object.fromEntries([...searchParams])
        const queryWithParam = { ...query, ...searchQuery }
        handlePageTo(queryWithParam, 1, 20)
        getKnowledgeBaseData()
        getAccountIdData()
    }, [user?.token])

    //页面跳转
    const handlePageTo = (query, page, size, touchLoading = true) => {
        // setAccountSelectValue(query.accountID)
        setQuery(query)
        form.setFieldsValue(query)
        if (!!touchLoading) setLoading(true)
        setPage(page)
        setSize(size)
        jsonRequest.get("/baseMessage/room-base/list", { page, size, ...query })
            .then(response => response.json())
            .then(({ data }) => {
                setData(data)
                setLoading(false)
            })
            .catch(() => { })
    }

    // 新增完成
    const addFinish = () => {
        addForm.validateFields().then((values) => {
            // 手动验证字段
            const errors = {}
            if (!values.accountID) {
                errors.accountID = "请选择主账号"
            }
            if (!values.roomName) {
                errors.roomName = "请选择群"
            }
            if (!values.knowledgeName) {
                errors.knowledgeName = "请选择知识库"
            }
            // 如果有错误，显示错误消息并中止提交
            if (Object.keys(errors).length > 0) {
                setAddFormErrors(errors)
                return
            }
            const baseID = values.knowledgeName
            const ownerID = values.roomName
            const enable = true
            const ownerType = 'bot_room'
            jsonRequest.post("/vector/setting", { accountID: values.accountID, baseID, enable, ownerType, ownerID })
                .then(response => response.json())
                .then(() => {
                    handlePageTo(query, page, size)
                    message.success('添加成功')
                })
                .catch(() => { })
            addForm.resetFields()
            addCancel()
            setIsSelect(true)
        })
    }

    // 查看完成
    const onLookFinish = () => {
        editForm.validateFields().then((values) => {
            // 手动验证字段
            const errors = {};
            if (!values.knowledgeName) {
                errors.knowledgeName = "请选择知识库"
            }
            // 如果有错误，显示错误消息并中止提交
            if (Object.keys(errors).length > 0) {
                setEditFormErrors(errors)
                return
            }
            // 需要做一个类型判断
            const baseID = typeof values.knowledgeName === 'number' ? values.knowledgeName :
                knowledgeBaseData.filter(item => item.label === values.knowledgeName)[0].value
            const id = parseInt(editForm.getFieldValue('id'))
            jsonRequest.post("/baseMessage/updateById", { id: id, baseID: baseID })
                .then(response => response.json())
                .then(() => {
                    handlePageTo(query, page, size)
                    message.success('配置成功')
                })
                .catch(() => {
                    message.error('配置失败')
                })
            editForm.resetFields()
            onLookCancel()
        })
    }

    // 取消新增
    const addCancel = () => {
        setAddFormErrors({})
        setAddVisible(false)
        setIsSelect(true)
    }

    // 取消查看配置界面
    const onLookCancel = () => {
        setEditFormErrors({})
        setEditVisible(false)
    }

    const onLookKnowledge = (record) => {
        editForm.setFieldsValue({ ...record })
        setEditVisible(true)
    }

    // 搜索功能
    const handleSearch = (values) => {
        setQuery({ ...query, ...values })
        handlePageTo({ ...query, ...values }, page, size)
    }

    // 删除功能
    const handleDelete = (id) => {
        Modal.confirm({
            centered: true,
            width: 400,
            title: '删除',
            content: '确认删除该账号吗？',
            onOk: async (close) => {
                try {
                    await jsonRequest.post(`/baseMessage/deleteById`, { id })
                    handlePageTo(query, page, size)
                    close()
                    message.success('删除成功')
                } catch (error) {
                    message.error('删除失败')
                }
            },
        })
    }

    // 终端设置请求参数
    const handleProfileTerminalSet = (id, base, value) => {
        let baseID = null;
        knowledgeBaseData.forEach((item) => {
            if (item.label === base) {
                baseID = item.value;
            }
        });
        jsonRequest.post("/baseMessage/updateById", { id, baseID, enable: value })
            .then(response => response.json())
            .then(({ data }) => {
                // handlePageTo(query, page, size)
            })
    }

    return <div className="page user">
        <Modal
            centered
            title="知识库配置"
            open={editVisible}
            onOk={onLookFinish}
            onCancel={onLookCancel}
            afterClose={() => editForm.resetFields()}
            width={500}
        >
            <Form form={editForm} style={{ maxWidth: '300px', marginLeft: "70px" }} >
                <Form.Item label="群名：" name="roomName">
                    <Input readOnly></Input>
                </Form.Item>
                <Form.Item label="知识库：" name="knowledgeName"
                    validateStatus={editFormErrors.knowledgeName ? "error" : ""}
                    help={editFormErrors.knowledgeName}>
                    <Select
                        allowClear
                        placeholder="选择数据库"
                        options={knowledgeBaseData}
                    />
                </Form.Item>
            </Form>
        </Modal>

        <Modal
            centered
            title="新增群配置"
            open={addVisible}
            onOk={addFinish}
            onCancel={addCancel}
            afterClose={() => {
                addForm.resetFields()
                setIsSelect(true)
            }}
            width={500}
        >
            <Form form={addForm} style={{ maxWidth: '300px', marginLeft: "70px" }} >
                <Form.Item label="主账号：" name="accountID"
                    validateStatus={addFormErrors.accountID ? "error" : ""}
                    help={addFormErrors.accountID}>
                    <Select
                        allowClear
                        placeholder="请选择主账号"
                        options={accountIdData}
                        onChange={(value) => {
                            addForm.resetFields(['roomName'])
                            getRoomData(value)
                            setIsSelect(false)
                        }}
                    />
                </Form.Item>
                <Form.Item label="群名：" name="roomName"
                    validateStatus={addFormErrors.roomName ? "error" : ""}
                    help={addFormErrors.roomName}>
                    <Select
                        allowClear
                        placeholder="请选择群"
                        options={roomData}
                        disabled={isSelect}
                    />
                </Form.Item>
                <Form.Item label="知识库：" name="knowledgeName"
                    validateStatus={addFormErrors.knowledgeName ? "error" : ""}
                    help={addFormErrors.knowledgeName}>
                    <Select
                        allowClear
                        placeholder="请选择数据库"
                        options={knowledgeBaseData}
                    />
                </Form.Item>
            </Form>
        </Modal>

        <h1>
            群列表
            <Divider type="vertical" />
        </h1>

        <div className="search-bar">
            <Form form={form} autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={{ query }} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="accountId" label="账号ID(昵称)">
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <Form.Item name="roomName" label="群名" >
                    <Input placeholder="模糊查询" allowClear></Input>
                </Form.Item>
                <ButtonGroup>
                    <Button type="primary" htmlType="submit" loading={loading}>查询</Button>
                    <Button onClick={() => handleSearch(defaultQuery)} htmlType="reset">清空</Button>
                </ButtonGroup>
                <div style={{ marginLeft: 'auto' }}>
                    <Button type="primary" onClick={() => setAddVisible(true)} >添加</Button>
                </div>
            </Form>
        </div>

        <Table dataSource={data.result}
            size="middle"
            className="flex-table"
            loading={loading}
            pagination={false} rowKey={d => d.id}>
            <Table.Column align="center" width={100} title="所属账号昵称" dataIndex="accountId" key="accountId"
                render={(text, item) =>
                    <Link onClick={() =>
                        handleSearch({ accountId: item.accountId })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={200} title="群名或昵称" dataIndex="roomName" key="roomName"
                render={(text, item) =>
                    <Link onClick={() =>
                        handleSearch({ roomName: item.roomName })}>{text} <SearchOutlined /></Link>} />
            <Table.Column align="center" width={100} title="群成员总数" dataIndex="raw" key="raw" render={(field) => field?.memberIdList?.length} />
            <Table.Column align="center" width={150} title="信息更新时间" dataIndex="updatedAt" key="updatedAt" render={(field, item) => dayjs(field || item.insertedAt).format("YYYY-MM-DD HH:mm")} />
            <Table.Column align="center" width={200} title="知识库" dataIndex="knowledgeName" key="knowledgeName"
                render={(text) => text}
            />
            <Table.Column align="center" width={100} title="终端启用" dataIndex="enable" key="enable" render={(field, item) => <Switch checkedChildren="启用" unCheckedChildren="不启用" defaultValue={field} onChange={(enable) => { handleProfileTerminalSet(item.id, item.knowledgeName, enable) }} />} />
            <Table.Column align="center" width={150} title="操作" render={(text, record) => <>
                <Button type="link" onClick={() => onLookKnowledge(record)}>配置<SettingOutlined /></Button>
                <Divider type="vertical" />
                <Button type="link"
                    onClick={() => handleDelete(record.id)}
                >删除 <MinusCircleOutlined /></Button>
            </>} />
        </Table>
        <Pagination style={{ margin: 5 }} total={data.total} current={data.page} pageSize={data.size} showQuickJumper onChange={(page, size) => { handlePageTo(query, page, size) }} />

    </div>

}