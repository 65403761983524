import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux"
import { NavActive } from "../features/Global"
import SideMenu from "./components/SideMenu";
import MemoryCard from "./MemoryCard"

export function BotModule() {
    const dispatch = useDispatch();

    dispatch(NavActive("Bot"));

    return <div className="module page user">
        <div className="side-menu">
            <SideMenu />
        </div>
        <div className="content">
            <Outlet />
        </div>
    </div>
}

export { MemoryCard }