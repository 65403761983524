import React from "react";
import { Menu } from 'antd';
import { useLocation, Link } from "react-router-dom";
import { OrderedListOutlined } from '@ant-design/icons';

export default function SideMenu(props) {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} selectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.ItemGroup key="bot" title="机器人">
            <Menu.Item key="/bot/memory-cards">
                <Link to="/bot/memory-cards">
                    <span><OrderedListOutlined /> 状态列表</span>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}
