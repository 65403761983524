import { Outlet } from "react-router-dom";
import { selectUser } from './features/Global';
import { useNavigate } from "react-router-dom";
// import { message } from "antd"
// import { Socket } from "phoenix"
// UI
import Nav from './components/Nav'
import { useSelector } from "react-redux";
// import { useSelector, useDispatch } from "react-redux";

function Layout(_props) {
    const navigate = useNavigate()
    const user = useSelector(selectUser)


    if (!user) {
        setTimeout(() => navigate("/sign_in", { replace: true }), 500)

        return <></>
    }
    // const socket = useSelector(selectSocket)
    // const dispatch = useDispatch();

    // // 启动socket
    // if (!socket) {
    //     const socket = new Socket("/ops-socket", { params: { token: "" }, reconnectAfterMs: () => 500, heartbeatIntervalMs: 5000 })
    //     socket.connect()
    //     socket.onError(error => {
    //         console.log(error)
    //     })
    //     socket.onClose(error => {
    //         message.error({ content: "与服务器丢失连接", key: "socket-close" })
    //     })
    //     socket.onOpen(() => {
    //         message.success({ content: "与服务器建立了连接", key: "socket-close", duration: 1 })
    //     })

    //     socket.onMessage((msg) => {
    //         switch (msg?.payload?.status) {
    //             case "error":
    //                 const exception_message = msg?.payload?.response?.message
    //                 if (!!exception_message) {
    //                     message.destroy()
    //                     message.error(exception_message)
    //                 }
    //                 break;

    //             default:
    //                 break;
    //         }
    //     })
    //     dispatch(socketInit(socket))
    // }

    return <div>
        <div className="App">
            <main role="main" className="container">
                <div style={{ padding: "5px" }}>
                    <Nav />
                </div>
                <Outlet />
            </main>
        </div>
    </div>
}

export default Layout;